import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Container,
  Tooltip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Menu,
  MenuItem,
  Divider,
  TextField,
  InputAdornment,
  Backdrop,
} from '@material-ui/core'
import { format } from 'date-fns'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import {
  SurveyBreadcrumb,
  TextChevronDown,
  InlineEditableValue,
  LockIcon,
  CustomIcon,
  SwapIcon,
  RightChevronIcon,
  Button,
  MenuSelectedItemIcon,
  Loading,
  FieldDataTypeMenuSection,
  NestedMenuItem,
  DeleteIcon,
  Modal,
  MODALS,
  TableClearIcon,
} from '~/legacy/components'
import {
  EditableStringCell,
  EditableNumberCell,
  EditableDateCell,
  EditableMultilineStringCell,
} from '~/legacy/components/tableComponents'
import {
  caseInsensitiveFindInArray,
  BULK_IMPORT_CONSTANTS,
  BULK_IMPORT_VALIDATORS,
  BULK_IMPORT_HELPERS,
  caseInsensitiveCompare,
} from '~/legacy/utils'
import { useApiHelper } from '~/legacy/utils/hooks'
import { LEASE_PROJECT_BY_ID } from '~/legacy/consts'

const BORDERED_CELL = {
  borderLeft: '1px solid #E0E0E0',
  borderTop: '1px solid #E0E0E0',
  borderBottom: 0,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backdropText: {
    fontSize: '18px',
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionsSticky: {
    borderBottom: '1px solid #e0e0e0',
  },
  breadcrumb: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '44px', // button height
  },
  root: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    padding: '0',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    marginLeft: 0,
    paddingBottom: (props) => `${props.LEFT_CONTAINER_HORIZ_PADDING_PX}px`,
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    padding: (props) => `24px 0 ${props.LEFT_CONTAINER_HORIZ_PADDING_PX}px 0`,
    borderBottom: '1px solid #E0E0E0',
  },
  pagePadding: {
    paddingTop: 0,
    paddingRight: (props) => `${props.LEFT_CONTAINER_HORIZ_PADDING_PX}px`,
    paddingBottom: 0,
    paddingLeft: (props) => `${props.LEFT_CONTAINER_HORIZ_PADDING_PX}px`,
  },
  titleContainer: {
    position: 'sticky',
    left: 0,
    width: '100%',
    zIndex: 10,
    background: 'white',
  },
  maxWidth: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  fieldValueCell: {
    width: '100px',
    padding: '12px 16px 12px 16px',
  },
  borderedCell: BORDERED_CELL,
  bottomBorderedCell: {
    borderLeft: '1px solid #E0E0E0',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
  bottomLeftCorner: {
    borderRadius: '0 0 0 4px',
  },
  bottomRightCorner: {
    borderRadius: '0 0 4px 0',
  },
  rightSide: {
    borderRight: '1px solid #E0E0E0',
  },
  notRightSide: {
    borderRight: 0,
  },
  fieldValueCellValueContainer: {
    maxHeight: '56px', // 80 - 12 - 12 padding
    display: 'flex',
    alignItems: 'center',
  },
  numConflicts: {
    color: '#666',
    marginLeft: '16px',
  },
  conflictLineHeight: {
    lineHeight: '14px',
  },
  hasConflicts: {
    color: '#DD421A',
  },
  importButton: {
    marginLeft: 'auto',
    height: '36px',
  },
  disabledButton: {
    color: '#666 !important',
  },
  numListingsConflictsContainer: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '68px',
    position: 'sticky',
    top: -1,
    left: 0,
    width: '100%',
    background: 'white',
    zIndex: 10,
    alignItems: 'center',
  },
  csvListingsTableContainer: {
    height: '100%',
    overflowX: 'visible !important',
    overflowY: 'visible !important',
  },
  csvListingsTable: {
    borderCollapse: 'separate',
  },
  tooltipPlacementBottom: {
    margin: '8px 0',
  },
}))

const MENU_ITEM_HORIZ_PADDING_PX = 12

const useHeaderCellStyles = makeStyles({
  fieldHeaderMenuSection: {
    color: '#666',
    paddingTop: '12px',
    paddingBottom: '6px',
  },
  firstSection: {
    paddingTop: '32px',
  },
  secondSection: {
    paddingTop: '20px',
  },
  fieldHeaderMenuNameValue: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  disabledFieldHeaderNameValue: {
    paddingTop: '0',
    display: 'flex',
    flexDirection: 'row',
  },
  disabledFieldHeaderName: {
    padding: '6px 0 7px',
  },
  lockIcon: {
    marginLeft: 'auto',
  },
  divider: {
    marginLeft: `${MENU_ITEM_HORIZ_PADDING_PX}px`,
    marginRight: `${MENU_ITEM_HORIZ_PADDING_PX}px`,
    paddingLeft: '0',
    paddingRight: '0',
  },
  menuItem: {
    paddingLeft: `${MENU_ITEM_HORIZ_PADDING_PX}px`,
    paddingRight: `${MENU_ITEM_HORIZ_PADDING_PX}px`,
    alignItems: 'center',
  },
  valueClearInputIcon: {
    // height: '16px',
    cursor: 'pointer',
    marginRight: '-5px',
    color: '#e0e0e0',
  },
  menuItemIcon: {
    marginRight: '12px',
    color: '#111',
  },
  headerIcon: {
    marginRight: '8px',
  },
  rightMenuArrow: {
    marginLeft: 'auto',
  },
  menu: {
    width: '240px',
  },
  menuItemRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    margin: 'auto',
  },
  iconContainer: {
    width: '32px',
    marginRight: '8px',
    display: 'flex',
  },
  headerCell: {
    backgroundColor: '#F9F9F9',
    padding: '6px 16px',
  },
  borderedCell: BORDERED_CELL,
  rightSide: {
    borderRight: '1px solid #E0E0E0',
  },
  notRightSide: {
    borderRight: 0,
  },
  headerNameContainer: {
    color: '#666',
    backgroundColor: (props) =>
      props.newHeader.error ? 'rgba(221, 66, 26, .15)' : '',
    height: '42px',
    maxHeight: '42px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    cursor: 'pointer',
  },
  chevronDown: {
    color: '#666',
  },
  selectedItem: {
    marginLeft: 'auto',
    color: '#666',
  },
  menuSectionTitle: {
    lineHeight: '12px',
    fontFamily: 'Inter-Medium',
  },
  underline: {
    borderBottom: '2px solid white',
    '&:after': {
      borderBottom: '2px solid white',
    },
  },
  topLeftCorner: {
    borderRadius: '4px 0 0 0 ',
  },
  topRightCorner: {
    borderRadius: '0 4px 0 0 ',
  },
  bothCorners: {
    borderRadius: '4px 4px 0 0 ',
  },
})

// Update a field value. The user has entered something new!
// We update the field when setting the value, and update the
//   already validated fields, so we don't have to use state management and
//   validate every single other cell.
const updateFieldValue = (
  header,
  listing,
  index,
  newValue,
  validatedListings,
  setValidatedListings,
  callback = () => {} // TODO: need this?
) => {
  const newValidatedListings = [...validatedListings]
  // Use the field type's validator function to get the new validated value, including any errors
  const newValidatedListingField = header.fieldDataType.validator(newValue)

  // replace the old value on the listing with the new
  newValidatedListings[index] = {
    ...listing,
    [header.index]: newValidatedListingField,
  }

  // Update the validated listings
  setValidatedListings(newValidatedListings)
  callback()
}

const defaultStyleOverrides = (cell, isSemicolon) => {
  const defaultOverrides = {
    container: {
      padding: '8px',
      cursor: isSemicolon ? '' : 'pointer',
    },
  }
  if (cell.error) {
    defaultOverrides.hoverBackgroundColor = 'rgba(221, 66, 26, .15)'
  }
  return defaultOverrides
}

// The cell displayed on the table to the user for the Address
const AddressFieldCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
  csvHeaderIndexLookup,
}) => {
  let listingAddress = ''
  let listingCity = ''
  let listingState = ''
  let listingZipcode = ''

  const getAddressValue = () => {
    if (!cell.error) {
      listingAddress = cell.value.GOOGLE_ADDRESS.address
      listingCity = cell.value.GOOGLE_ADDRESS.city
      listingState = cell.value.GOOGLE_ADDRESS.state
      listingZipcode = cell.value.GOOGLE_ADDRESS.zipcode
      // TODO: only need the GOOGLE_ADDRESS here for display...
      return {
        GOOGLE_ADDRESS: { ...cell.value.GOOGLE_ADDRESS },
        ...cell.value.GOOGLE_AUTOCOMPLETE_RESULTS,
      }
    }
    return null
  }

  const addressValue = getAddressValue()

  // If there's no error, then use the google address parts. Else, use whatever was
  //   provided in the csv.
  if (cell.error) {
    listingAddress = cell.csvValue.address
    listingCity = cell.csvValue.city
    listingState = cell.csvValue.state
    listingZipcode = cell.csvValue.zipcode
  }

  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell)}
      hoverOnContainer
      placeholder="Enter a value"
      onClick={() => {}}
      type="address"
      fieldDataTypeId={header.fieldDataType.id}
      updateValueApiCallback={(data, newerValue) => {
        // Validate the address and update the listing's value.
        // The address validator has a slightly different signature than then rest of
        //   the fields so we just write it out here rather than using updateFieldValue()
        const newValidatedListings = [...validatedListings]

        const newValidatedListingField = BULK_IMPORT_VALIDATORS.validateAddress(
          newerValue,
          listing,
          csvHeaderIndexLookup
        )

        const newValidatedListing = {
          ...listing,
          [header.index]: newValidatedListingField,
        }
        newValidatedListings[index] = newValidatedListing

        // Update the validated listings
        setValidatedListings(newValidatedListings)
      }}
      value={addressValue || null}
      // TODO: This is a huge mess...
      formatDisplayValue={(newValue) => {
        return newValue
          ? {
              address: newValue.GOOGLE_ADDRESS.address,
              state: newValue.GOOGLE_ADDRESS.state,
              city: newValue.GOOGLE_ADDRESS.city,
              zipcode: newValue.GOOGLE_ADDRESS.zipcode,
            }
          : null
      }}
      displayValueOverride={
        listingAddress || listingState || listingCity || listingZipcode
          ? {
              address: listingAddress,
              state: listingState,
              city: listingCity,
              zipcode: listingZipcode,
            }
          : null
      }
      prepareNewValue={(newValue) => {
        return {
          ...newValue.GOOGLE_AUTOCOMPLETE_RESULTS,
          GOOGLE_ADDRESS: { ...newValue.GOOGLE_ADDRESS },
        }
      }}
    />
  )
}

// The cell displayed on the table to the user for numeric types
const NumericFieldCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <EditableNumberCell
      value={rawValue}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          newValue,
          validatedListings,
          setValidatedListings
        )
      }
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue || null : undefined}
      formatDisplayValue={() =>
        !cell.error
          ? BULK_IMPORT_HELPERS.getNumberValueForDisplay(
              rawValue,
              header.fieldDataType.id
            )
          : rawValue
      }
    />
  )
}

// Date cells
const DateFieldCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  const noTimeRawValue =
    cell.error || !rawValue
      ? rawValue
      : BULK_IMPORT_HELPERS.prepareDate(rawValue)

  return (
    <EditableDateCell
      value={noTimeRawValue}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          newValue,
          validatedListings,
          setValidatedListings
        )
      }
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? noTimeRawValue : undefined}
      formatDisplayValue={(valueToFormat) =>
        valueToFormat && !cell.error
          ? format(valueToFormat, 'MMM d, y')
          : valueToFormat
      }
    />
  )
}

const ListSemicolonCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <InlineEditableValue
      classesIn={{}}
      styleOverrides={defaultStyleOverrides(cell, true)}
      hoverOnContainer
      placeholder="Enter a value"
      autoFocus={false}
      onClick={() => {}}
      type="multi-select"
      fieldDataTypeId={header.fieldDataType.id}
      options={[]}
      value={rawValue}
      displayValueOverride={cell.error ? rawValue : undefined}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          BULK_IMPORT_HELPERS.joinListString(newValue),
          validatedListings,
          setValidatedListings
        )
      }
    />
  )
}

const StringCell = ({
  cell,
  header,
  listing,
  index,
  validatedListings,
  setValidatedListings,
  CellClass = EditableStringCell,
}) => {
  const rawValue = cell.error ? cell.csvValue : cell.value
  return (
    <CellClass
      value={rawValue}
      updateValueApiCallback={(data, newValue) =>
        updateFieldValue(
          header,
          listing,
          index,
          newValue,
          validatedListings,
          setValidatedListings
        )
      }
      fieldType={header.fieldDataType.id}
      placeholder="Enter a value"
      styleOverrides={defaultStyleOverrides(cell)}
      displayValueOverride={cell.error ? rawValue : undefined}
      field={{ label: header.displayName }}
    />
  )
}

const MultilineStringCell = (props) => (
  <StringCell CellClass={EditableMultilineStringCell} {...props} />
)

const WhiteTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e0e0e0', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#666', // Solid underline on hover
    },
    '& .MuiInputBase-input': {
      height: '22px',
    },
  },
})(TextField)

// The header displayed on the table.
const FieldHeaderCell = ({
  header,
  index,
  numColumns,
  updateLoadedListingFields,
  mappedStandardFields,
  loadedListingFieldsObj,
  setLoadedListingFieldsObj,
  setMappedStandardFields,
  setRemoveColumnHeader,
  useNewCustomFields = false,
}) => {
  // We keep track of the user changes to the header, and when the menu closes, save and persist those changes
  const [newHeader, setNewHeader] = useState({ ...header })
  const [newHeaderError, setNewHeaderError] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (header) {
      setNewHeader({ ...header })
    } else {
      setNewHeader({})
    }
  }, [header])

  const classes = useHeaderCellStyles({ newHeader })

  // Menu stuff
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const anchorRef = React.useRef(null)
  const handleClick = () => {
    setAnchorEl(anchorRef.current)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const handleClose = () => {
    if (!newHeaderError) {
      // Only update if there was a change, just to speed things up...
      if (newHeader.displayName !== header.displayName) {
        updateLoadedListingFields(newHeader.index, newHeader)
      }
    }

    closeMenu()
  }

  // Make sure we are properly setting the error message anytime the new header changes as the user modifies it
  useEffect(() => {
    if ((newHeader && !newHeader.reserved) || !newHeader) {
      if (!newHeader.displayName) {
        setNewHeaderError('Enter a name')
      } else if (
        caseInsensitiveFindInArray(
          useNewCustomFields
            ? BULK_IMPORT_CONSTANTS.NEW_RESERVED_FIELDS
            : BULK_IMPORT_CONSTANTS.RESERVED_FIELDS,
          'displayName',
          newHeader.displayName
        )
      ) {
        setNewHeaderError('This name is reserved')
      } else {
        setNewHeaderError('')
      }
    } else {
      setNewHeaderError('')
    }
  }, [newHeader])

  // Get the component for the icon from the field type
  const IconComponent = header.fieldDataType.icon

  // For the top left / right headers, make the corners rounded...
  const borderClasses = []

  if (index === 0 && index === numColumns - 1) {
    borderClasses.push(classes.rightSide)
    borderClasses.push(classes.bothCorners)
  } else {
    if (index === 0) {
      borderClasses.push(classes.topLeftCorner)
    }
    if (index === numColumns - 1) {
      borderClasses.push(classes.rightSide)
      borderClasses.push(classes.topRightCorner)
    } else {
      borderClasses.push(classes.notRightSide)
    }
  }

  return (
    <TableCell
      align="center"
      className={clsx(
        classes.borderedCell,
        classes.headerCell,
        ...borderClasses
      )}
      ref={anchorRef}
    >
      <Tooltip
        title={newHeader.error || ''}
        disableFocusListener
        disableTouchListener
      >
        <div className={classes.headerNameContainer} onClick={handleClick}>
          <IconComponent className={classes.headerIcon} />
          <Typography
            noWrap
            variant="body2"
            style={{ letterSpacing: '.4px', fontFamily: 'Inter-Medium' }}
          >
            {header.displayName.toUpperCase()}
          </Typography>
          <TextChevronDown className={classes.chevronDown} />
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        elevation={2}
        // Open bottom centered
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: '240px',
            minWidth: '240px',
            maxHeight: '375px',
          },
        }}
      >
        <div className={clsx(classes.fieldHeaderMenuSection, classes.menuItem)}>
          <Typography variant="h3" className={classes.menuSectionTitle}>
            Field Name
          </Typography>
        </div>
        <div
          className={clsx(
            classes.fieldHeaderMenuNameValue,
            classes.disabledFieldHeaderNameValue,
            classes.menuItem
          )}
        >
          {/* The name of the field */}
          {newHeader.reserved || newHeader.disableAllEditing ? (
            <>
              <Typography className={classes.disabledFieldHeaderName}>
                {newHeader.displayName}
              </Typography>
              <LockIcon className={classes.lockIcon} />
            </>
          ) : (
            <WhiteTextField
              fullWidth
              id="standard-basic"
              value={newHeader.displayName}
              variant="standard"
              color="primary"
              onKeyDown={(e) => e.stopPropagation()}
              InputProps={{
                endAdornment:
                  newHeader.reserved || newHeader.disableAllEditing ? (
                    ''
                  ) : (
                    <InputAdornment position="end">
                      <TableClearIcon
                        className={classes.valueClearInputIcon}
                        onClick={() => {
                          setNewHeader({ ...newHeader, displayName: '' })
                        }}
                        onMouseDown={(event) => event.preventDefault()}
                      />
                    </InputAdornment>
                  ),
                autoFocus: true,
              }}
              error={!!newHeaderError}
              helperText={newHeaderError || ''}
              FormHelperTextProps={{ style: { ...theme.typography.h5 } }}
              onChange={(event) =>
                setNewHeader({ ...newHeader, displayName: event.target.value })
              }
            />
          )}
        </div>
        {!!(newHeader.reserved && !newHeader.disableAllEditing) && (
          <Divider
            sx={{ my: 0.5 }}
            className={clsx(classes.menuItem, classes.divider)}
          />
        )}

        {/* Menu section for the field data type. Potentially editable */}
        {!!(!newHeader.reserved && !newHeader.disableAllEditing) && (
          <FieldDataTypeMenuSection
            classesIn={{
              fieldHeaderMenuSection: classes.firstSection,
            }}
            onClick={(newFieldType) => {
              updateLoadedListingFields(header.index, {
                ...header,
                fieldDataType: newFieldType,
              })
              closeMenu()
            }}
            fieldDataTypeId={newHeader.fieldDataType.id}
          />
        )}

        {/* Field types - space or building */}
        {!!(!newHeader.reserved && !newHeader.disableAllEditing) && [
          <div
            key="fieldTypeKey"
            className={clsx(
              classes.fieldHeaderMenuSection,
              classes.secondSection,
              classes.menuItem
            )}
          >
            <Typography variant="h3" className={classes.menuSectionTitle}>
              Field Type
            </Typography>
          </div>,
          BULK_IMPORT_CONSTANTS.USER_SELECTABLE_FIELD_TYPES_ORDERED.map(
            (fieldType) => {
              const FieldIconComponent = fieldType.icon
              return (
                <MenuItem
                  className={classes.menuItem}
                  disableGutters
                  key={fieldType.id}
                  onClick={() => {
                    updateLoadedListingFields(newHeader.index, {
                      ...newHeader,
                      fieldType,
                    })
                    closeMenu()
                  }}
                >
                  <FieldIconComponent className={classes.menuItemIcon} />
                  <Typography color="textPrimary" variant="h3">
                    {fieldType.name}
                  </Typography>
                  {fieldType.id === newHeader.fieldType.id && (
                    <MenuSelectedItemIcon className={classes.selectedItem} />
                  )}
                </MenuItem>
              )
            }
          ),
        ]}

        {!newHeader.disableAllEditing && [
          <div
            key="fieldMatchingKey"
            className={clsx(
              classes.fieldHeaderMenuSection,
              !(!newHeader.reserved && !newHeader.disableAllEditing)
                ? classes.firstSection
                : classes.secondSection,
              classes.menuItem
            )}
          >
            <Typography variant="h3" className={classes.menuSectionTitle}>
              Field Matching
            </Typography>
          </div>,
          <NestedMenuItem
            key="changeFieldMatchMenuItemKey"
            MenuProps={{
              PaperProps: {
                style: {
                  width: '240px',
                  minWidth: '240px',
                  marginTop: '-8px', // align the nested modal by moving it up by the amount of top padding in the menu
                },
              },
              elevation: 2,
            }}
            disableGutters
            className={classes.menuItem}
            label={
              <div className={classes.menuItemRow}>
                <SwapIcon className={classes.menuItemIcon} />
                <Typography variant="h3">Change field match</Typography>
              </div>
            }
            parentMenuOpen={!!open}
            onClick={() => {}}
            rightIcon={<RightChevronIcon className={classes.rightMenuArrow} />}
          >
            {mappedStandardFields.map((mappableField, mappableFieldIndex) => (
              <MenuItem
                key={mappableField.displayName}
                onClick={() => {
                  BULK_IMPORT_HELPERS.matchMappableField(
                    mappableField,
                    newHeader,
                    loadedListingFieldsObj,
                    setLoadedListingFieldsObj,
                    mappedStandardFields,
                    setMappedStandardFields,
                    mappableFieldIndex
                  )
                }}
                style={{
                  width: '240px',
                  minWidth: '240px',
                  paddingLeft: '8px',
                  paddingRight: '12px',
                  height: '36px',
                }}
              >
                <div className={classes.iconContainer}>
                  {React.createElement(mappableField.icon, {
                    className: classes.icon,
                  })}
                </div>
                <Typography variant="h3">
                  {mappableField.displayName}
                </Typography>
                {mappableField.match === newHeader.index && (
                  <MenuSelectedItemIcon className={classes.selectedItem} />
                )}
              </MenuItem>
            ))}
          </NestedMenuItem>,
        ]}

        {/* Option to convert the reserved field to a custom field (ie: Date Available to Random Date) */}
        {!!(newHeader.reserved && !newHeader.disableAllEditing) && (
          <MenuItem
            key="convertToCustomFieldKey"
            disableGutters
            onClick={() => {
              if (!newHeaderError) {
                updateLoadedListingFields(newHeader.index, {
                  ...newHeader,
                  match: null,
                  reserved: null,
                })
                // Unset any matches on our standard fields
                const newMappedStandardFields = mappedStandardFields.map(
                  (mappedStandardField) => {
                    if (mappedStandardField.match === newHeader.index) {
                      return { ...mappedStandardField, match: null }
                    }
                    return mappedStandardField
                  }
                )
                setMappedStandardFields(newMappedStandardFields)
                closeMenu()
              }
            }}
            className={classes.menuItem}
            disabled={!!newHeaderError}
          >
            <CustomIcon className={classes.menuItemIcon} />
            <Typography variant="h3">Convert to Custom Field</Typography>
          </MenuItem>
        )}

        {/* Option to remove a field */}
        {!newHeader.disableAllEditing && [
          <div
            key="fieldMatchingKey"
            className={clsx(
              classes.fieldHeaderMenuSection,
              classes.secondSection,
              classes.menuItem
            )}
          >
            <Typography variant="h3" className={classes.menuSectionTitle}>
              Remove
            </Typography>
          </div>,
          <MenuItem
            key="removeColumn"
            disableGutters
            onClick={() => {
              setRemoveColumnHeader(newHeader)
            }}
            className={classes.menuItem}
          >
            <DeleteIcon className={classes.menuItemIcon} />
            <Typography variant="h3">Delete Column</Typography>
          </MenuItem>,
        ]}
      </Menu>
    </TableCell>
  )
}

const ListingFieldsRow = ({
  index,
  orderedListingFields,
  listing,
  validatedListings,
  setValidatedListings,
  csvHeaderIndexLookup,
  LEFT_CONTAINER_HORIZ_PADDING_PX,
}) => {
  const classes = useStyles({ LEFT_CONTAINER_HORIZ_PADDING_PX })
  const filteredOrderedListingFields = orderedListingFields.filter(
    (header) => listing[header.index] !== undefined
  )
  // If its the bottom row, need a bottom border
  const borderClass =
    index === validatedListings.length - 1
      ? classes.bottomBorderedCell
      : classes.borderedCell
  return (
    <TableRow>
      {filteredOrderedListingFields.map((header, cellIndex) => {
        const cell = listing[header.index]
        let displayCell = null

        if (
          header.fieldDataType.id ===
          BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.ADDRESS.id
        ) {
          displayCell = (
            <AddressFieldCell
              cell={cell}
              header={header}
              listing={listing}
              index={index}
              validatedListings={validatedListings}
              setValidatedListings={setValidatedListings}
              csvHeaderIndexLookup={csvHeaderIndexLookup}
            />
          )
        } else if (
          [
            BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.NUMBER.id,
            BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.SIZE_SQFT.id,
            BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.CURRENCY_USD.id,
          ].includes(header.fieldDataType.id)
        ) {
          displayCell = (
            <NumericFieldCell
              cell={cell}
              header={header}
              listing={listing}
              index={index}
              validatedListings={validatedListings}
              setValidatedListings={setValidatedListings}
            />
          )
        } else if (
          header.fieldDataType.id ===
          BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id
        ) {
          displayCell = (
            <DateFieldCell
              cell={cell}
              header={header}
              listing={listing}
              index={index}
              validatedListings={validatedListings}
              setValidatedListings={setValidatedListings}
            />
          )
        } else if (
          !cell.error &&
          header.fieldDataType.id ===
            BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.LIST.id
        ) {
          displayCell = (
            <ListSemicolonCell
              cell={cell}
              header={header}
              listing={listing}
              index={index}
              validatedListings={validatedListings}
              setValidatedListings={setValidatedListings}
            />
          )
        } else if (
          !cell.error &&
          header.fieldDataType.id ===
            BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.MULTILINE_STRING.id
        ) {
          displayCell = (
            <MultilineStringCell
              cell={cell}
              header={header}
              listing={listing}
              index={index}
              validatedListings={validatedListings}
              setValidatedListings={setValidatedListings}
            />
          )
        } else {
          displayCell = (
            <StringCell
              cell={cell}
              header={header}
              listing={listing}
              index={index}
              validatedListings={validatedListings}
              setValidatedListings={setValidatedListings}
            />
          )
        }

        // Bottom left/right corners need radius
        let cornerBorderClass = ''
        // bottom row
        if (index === validatedListings.length - 1) {
          if (cellIndex === 0) {
            // first cell in row
            cornerBorderClass = classes.bottomLeftCorner
          } else if (cellIndex === filteredOrderedListingFields.length - 1) {
            // last in row
            cornerBorderClass = classes.bottomRightCorner
          }
        }
        const rightBorderClass =
          cellIndex === filteredOrderedListingFields.length - 1
            ? classes.rightSide
            : classes.notRightSide

        return (
          <TableCell
            key={`${header.index}-${header.fieldDataType.id}`}
            className={clsx(
              classes.fieldValueCell,
              borderClass,
              rightBorderClass,
              cornerBorderClass
            )}
          >
            <Tooltip
              title={cell.error || ''}
              disableFocusListener
              disableTouchListener
              classes={{
                tooltipPlacementBottom: classes.tooltipPlacementBottom,
              }}
            >
              <div
                className={classes.fieldValueCellValueContainer}
                style={{
                  backgroundColor: cell.error ? 'rgba(221, 66, 26, .15)' : '',
                  color: cell.error ? '#DD421A' : '',
                }}
              >
                {displayCell}
              </div>
            </Tooltip>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

function SurveyBulkImport({
  survey,
  removedHeaderIndices,
  setRemovedHeaderIndices,
  validatedListings,
  setValidatedListings,
  orderedListingFields,
  csvHeaderIndexLookup,
  loadedListingFieldsObj,
  setLoadedListingFieldsObj,
  mappedStandardFields,
  setMappedStandardFields,
  LEFT_CONTAINER_HORIZ_PADDING_PX,
  uploadedCsvFile,
}) {
  const classes = useStyles({ LEFT_CONTAINER_HORIZ_PADDING_PX })
  const apiHelper = useApiHelper()
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [removeColumnHeader, setRemoveColumnHeader] = useState(null)
  const [removeColumnHeaderLoading, setRemoveColumnHeaderLoading] =
    useState(false)
  const isNewCustomFieldsEnabled = survey.opted_in_new_custom_fields

  // Keep track of the conflicts with the values as well as the headers (name, type, etc)
  const [numFieldValueConflicts, setNumFieldValueConflicts] = useState(null)
  const [numFieldHeaderConflicts, setNumFieldHeaderConflicts] = useState(null)
  const numConflicts =
    numFieldValueConflicts !== null && numFieldHeaderConflicts !== null
      ? numFieldValueConflicts + numFieldHeaderConflicts
      : null

  // Whenver our validated listing data changes (which includes errors), tally up the conflicts
  useEffect(() => {
    let newNumConflicts = 0
    if (validatedListings && validatedListings.length) {
      validatedListings.forEach((listing) => {
        if (listing[-1] && listing[-1].error) newNumConflicts++
        Object.values(listing).forEach((value) => {
          if (value.error) newNumConflicts++
        })
      })
    }
    setNumFieldValueConflicts(newNumConflicts)
  }, [validatedListings])

  // Whenever our listing fields change (headers), tally up any errors
  useEffect(() => {
    let newNumHeaderConflicts = 0
    if (orderedListingFields && orderedListingFields.length) {
      const listingFieldSet = new Set()
      // Check for duplicates
      orderedListingFields.forEach((listingField) => {
        if (listingFieldSet.has(listingField.displayName)) {
          newNumHeaderConflicts++
        } else {
          listingFieldSet.add(listingField.displayName)
          // If there is any other with the header
          if (listingField.error) newNumHeaderConflicts++
        }
      })
    }
    setNumFieldHeaderConflicts(newNumHeaderConflicts)
  }, [orderedListingFields])

  const closeRemoveColumnHeaderModal = () => {
    setRemoveColumnHeaderLoading(false)
    setRemoveColumnHeader(null)
  }

  const updateLoadedListingFields = (listingFieldIndex, newListingField) => {
    setLoadedListingFieldsObj({
      ...loadedListingFieldsObj,
      [listingFieldIndex]: newListingField,
    })
  }

  // Sticky header and dynamically adding/removing the bottom border
  const [isSticky, setIsSticky] = useState(false)
  const ref = React.createRef()
  // mount
  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )

    observer.observe(cachedRef)

    // unmount
    return () => observer.unobserve(cachedRef)
  }, [])

  const importListingsDisabled =
    !validatedListings || !validatedListings.length || numConflicts !== 0

  return (
    <Container className={classes.root}>
      {/* Sticky horizontal only */}
      <div
        className={clsx(
          classes.pagePadding,
          classes.maxWidth,
          classes.titleContainer
        )}
      >
        <div className={clsx(classes.breadcrumb, classes.maxWidth)}>
          <SurveyBreadcrumb surveyId={survey.id} surveyName={survey.name} />
        </div>
        <div className={clsx(classes.maxWidth)}>
          <div className={classes.topBar}>
            <div className={classes.titleContainer}>
              <Typography variant="h1">{survey.name}</Typography>
            </div>
          </div>
        </div>
      </div>

      {/* Sticky both vertically and horizontally */}
      <div
        className={clsx(
          classes.pagePadding,
          classes.numListingsConflictsContainer,
          isSticky ? classes.actionsSticky : ''
        )}
        ref={ref}
      >
        <Typography variant="h3" className={classes.conflictLineHeight}>
          {validatedListings ? validatedListings.length : 0}
          {' '}
Listing
          {numConflicts === 1 ? '' : 's'}
        </Typography>
        <Typography
          variant="h3"
          className={clsx(
            classes.numConflicts,
            classes.conflictLineHeight,
            numConflicts !== 0 ? classes.hasConflicts : ''
          )}
        >
          {numConflicts || 0}
          {' '}
Conflict
          {numConflicts === 1 ? '' : 's'}
        </Typography>
        <Button
          className={clsx(
            classes.importButton,
            importListingsDisabled ? classes.disabledButton : ''
          )}
          color="primary"
          customHeight
          disabled={importListingsDisabled}
          onClick={() => {
            setBackdropOpen(true)
            const { listings, customFields } = getDataForSave({
              survey,
              loadedListingFieldsObj,
              validatedListings,
              orderedListingFields,
              useNewCustomFields: isNewCustomFieldsEnabled,
            })

            // Save the listings, then forward to the SDP
            apiHelper
              .addListingsBulk({
                listings,
                bulkImportCsvFileId: !!uploadedCsvFile && uploadedCsvFile.id,
                surveyId: survey.id,
                customFields,
              })
              .then((results) => {
                if (results && results.length) {
                  // TODO: Make SPA friendly by instead just reloading the survey, and closing all of the import stuff.
                  window.location.replace(
                    LEASE_PROJECT_BY_ID.replace(':id', survey.id)
                  )
                } else {
                  setBackdropOpen(false)
                }
              })
          }}
        >
          Import Listings
        </Button>
      </div>

      {/* Table of listings */}
      <div className={clsx(classes.pagePadding, classes.maxWidth)}>
        {validatedListings && validatedListings.length && (
          <TableContainer className={classes.csvListingsTableContainer}>
            <Table size="small" className={classes.csvListingsTable}>
              <TableHead>
                <TableRow>
                  {orderedListingFields.map((header, index) => (
                    <FieldHeaderCell
                      key={header.index}
                      header={header}
                      index={index}
                      numColumns={orderedListingFields.length}
                      updateLoadedListingFields={updateLoadedListingFields}
                      mappedStandardFields={mappedStandardFields}
                      loadedListingFieldsObj={loadedListingFieldsObj}
                      setLoadedListingFieldsObj={setLoadedListingFieldsObj}
                      setMappedStandardFields={setMappedStandardFields}
                      setRemoveColumnHeader={setRemoveColumnHeader}
                      useNewCustomFields={isNewCustomFieldsEnabled}
                    />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {validatedListings.map((listing, index) => (
                  /* eslint-disable react/no-array-index-key */
                  <ListingFieldsRow
                    key={index}
                    index={index}
                    orderedListingFields={orderedListingFields}
                    listing={listing}
                    validatedListings={validatedListings}
                    setValidatedListings={setValidatedListings}
                    csvHeaderIndexLookup={csvHeaderIndexLookup}
                    LEFT_CONTAINER_HORIZ_PADDING_PX={
                      LEFT_CONTAINER_HORIZ_PADDING_PX
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <div className={classes.backdropContent}>
          <Loading color="inherit" isLoading />
          <Typography className={classes.backdropText}>
            Importing Listings
          </Typography>
          <Typography>Your survey will be prepared shortly</Typography>
        </div>
      </Backdrop>
      <Modal
        content={MODALS.CONFIRM_MODAL}
        open={!!removeColumnHeader}
        onClose={closeRemoveColumnHeaderModal}
        childProps={{
          onConfirm: () => {
            setRemoveColumnHeaderLoading(true)
            // Add the header to remove to our set of removed headers
            const newRemovedHeaderIndices = new Set(removedHeaderIndices)
            newRemovedHeaderIndices.add(removeColumnHeader.index)
            setRemovedHeaderIndices(newRemovedHeaderIndices)
            closeRemoveColumnHeaderModal()
          },
          title: 'Are you sure?',
          text: 'Are you sure you want to remove this column from your table? This can’t be undone.',
          confirmButtonLabel: 'Remove',
          loading: removeColumnHeaderLoading,
        }}
      />
    </Container>
  )
}

const formatValueForBackend = (value, fieldDataTypeId) => {
  if (
    value &&
    fieldDataTypeId === BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id
  ) {
    return format(new Date(value.toDateString()), 'yyyy-MM-dd')
  }
  return value
}

const getDataForSave = ({
  survey,
  loadedListingFieldsObj,
  validatedListings,
  useNewCustomFields = false,
}) => {
  const allFields = Object.values(BULK_IMPORT_CONSTANTS.FIELDS)
  const specialFields = !useNewCustomFields
    ? allFields
        // We don't support address currently
        .filter(
          (field) =>
            field.fieldId !== BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.field
        )
    : // When using the new custom fields only the ones marked with `standard: true` are considered special fields
      allFields.filter((field) => field.standard)

  const allCustomFields = new Map()

  const listings = validatedListings.map((validatedListing) => {
    const addressFieldValue =
      validatedListing[BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index].value
        .GOOGLE_ADDRESS

    // Go through each field, and if its one of our special listing/building fields, then grab that value so we can map it [O(n^2), not great]
    // Keep track of the non-custom field indices
    const matchedFieldIndices = new Set()
    const matchedListingFields = {}
    const matchedBuildingFields = {}
    // For each field
    Object.entries(loadedListingFieldsObj).forEach(([key, field]) => {
      // For each special field, check if the field matches any of the special fields
      specialFields.forEach((specialField) => {
        if (
          caseInsensitiveCompare(field.displayName, specialField.displayName)
        ) {
          matchedFieldIndices.add(key)
          const matchedValue = formatValueForBackend(
            validatedListing[key].value || null,
            field.fieldDataType.id
          )
          if (
            specialField.fieldType.id ===
            BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
          ) {
            matchedBuildingFields[specialField.modelFieldName] = matchedValue
          } else {
            matchedListingFields[specialField.modelFieldName] = matchedValue
          }
        }
      })
    })

    const listingCustomFields = []
    const buildingCustomFields = []
    Object.entries(validatedListing).forEach(
      ([fieldIndex, fieldValue], index) => {
        const field = loadedListingFieldsObj[fieldIndex]

        // First, format the value for saving on backend
        const value = formatValueForBackend(
          fieldValue.value || null,
          field.fieldDataType.id
        )

        // Go through and get each custom field, formatted.
        // Skip the non-custom fields we've already added
        if (
          !matchedFieldIndices.has(fieldIndex) &&
          Number(fieldIndex) !== BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index
        ) {
          if (value !== undefined && value !== null && value !== '') {
            const customField = {
              name: field.displayName,
              value: value || null,
              type: field.fieldType.id,
              data_type: field.fieldDataType.id,
            }

            if (
              field.fieldType.id ===
              BULK_IMPORT_CONSTANTS.FIELD_TYPES.BUILDING.id
            ) {
              buildingCustomFields.push(customField)
            } else {
              listingCustomFields.push(customField)
            }
          }

          // When using the new custom fields, only add the custom fields that we haven't already added
          // We only need one instance of each as the definition not all the values we have inside the current loop
          if (useNewCustomFields && !allCustomFields.has(field.displayName)) {
            allCustomFields.set(field.displayName, {
              label: field.displayName,
              dataType: field.fieldDataType.id,
              fieldType: field.fieldType.id,
              order: index,
            })
          }
        }
      }
    )

    // Put together the listing and building data for the request
    const listingFormattedToSave = {
      ...matchedListingFields,
      custom_fields: listingCustomFields,
      survey_id: survey.id,
    }

    const buildingFormattedToSave = {
      ...matchedBuildingFields,
      custom_fields: buildingCustomFields,
      // address
      address: addressFieldValue.address,
      city: addressFieldValue.city,
      state: addressFieldValue.state,
      zipcode: addressFieldValue.zipcode,
      country: addressFieldValue.country,
      latitude: addressFieldValue.latitude,
      longitude: addressFieldValue.longitude,
    }

    return {
      listing: listingFormattedToSave,
      building: buildingFormattedToSave,
    }
  })

  return { listings, customFields: Array.from(allCustomFields.values()) }
}

export default SurveyBulkImport
