// @ts-check
import React, { useEffect, useState } from 'react'
import {
  caseInsensitiveCompare,
  caseInsensitiveFindInArray,
  getValidatedFieldValue,
  BULK_IMPORT_CONSTANTS,
} from '~/legacy/utils'

// Hook for much of the data we will need for bulk import
// TODO: Make these selectors so they don't have to be passed around
const useBulkImport = (
  { withNewCustomFields } = { withNewCustomFields: false }
) => {
  /*
    A list of leaseup fields we want across listings. We track the field here, it's display, type, and whether or not we've
    matched to a CSV column or not
  */
  const [mappedStandardFields, setMappedStandardFields] = useState(
    withNewCustomFields
      ? BULK_IMPORT_CONSTANTS.NEW_STANDARD_FIELDS
      : BULK_IMPORT_CONSTANTS.STANDARD_FIELDS
  )

  // The raw string headers pulled from the CSV, as well as a lookup dict of { headerString => index in loadedListingFieldsObj}
  const [rawCsvHeaders, setRawCsvHeaders] = useState(null)
  const [csvHeaderIndexLookup, setCsvHeaderIndexLookup] = useState(null)

  // A set of strings of CSV headers that the user uploaded and will be able to choose from to match fields on.
  const [userMatchableCsvHeaders, setUserMatchableCsvHeaders] = useState(null)

  // Variables for the listing data. We go from loadedListingsFromCsv => loadedListingsFormatted => validatedListings
  // 1. Array of listings loaded from the CSV. Keys are CSV columns.
  const [loadedListingsFromCsv, setLoadedListingsFromCsv] = useState(null)

  // 2. Array of listings loaded from the CSV with their address processed. Listing Keys are csv column index, except for special address fields
  // [{index: listingFieldValue, ...}, ...]
  // ie: [{0: '18 Tremont Street', 1: ''Boston', ...}, {}, ...]
  const [loadedListingsFormatted, setLoadedListingsFormatted] = useState(null)

  // 3. A list of validated listings, where each key is the index of the field in loadedListingFieldsObj
  // We keep track of the validated value, the original csv value, and whether or not there's an error
  // [{index: validatedListing, ...}, ...]
  // ie: [{3: {value: 'Office', csvValue: 'Office', error: null}, ...}, {}, ...]
  const [validatedListings, setValidatedListings] = useState(null)

  // Variables for the fields from the CSV. We go from loadedListingFieldsObj => orderedListingFields
  // 1. The fields we generated from the csv header columns. Validated listings will have data on keys matching these field names
  // {index: listingField, ...}
  // ie: {3: {displayName: 'Property Type', csvString: 'PropertyType', fieldDataType, fieldType, index: 3, matches}, {4: ...}, ...}
  const [loadedListingFieldsObj, setLoadedListingFieldsObj] = useState(null)

  // 2. Array of the listing fields we will show on the page, ordered. Also has error status
  // [listingField, ...]
  // ie: [{displayName: 'Property Type', csvString: 'PropertyType', error: null, fieldDataType, fieldType, index: 3, matches}, {4: ...}, ...]
  const [orderedListingFields, setOrderedListingFields] = useState(null)

  // Set of header indices that the user has removed via UI. Easier to hide them than it is to actually delete them.
  const [removedHeaderIndices, setRemovedHeaderIndices] = useState(new Set())

  // The actual file that was uploaded by the user, only so we can save the raw file
  const [uploadedCsvFile, setUploadedCsvFile] = useState(null)

  // When the raw CSV headers are set, do our default matching on them to produce a set of fields in the csv
  // 1. Strip out our specific address fields
  // 2. Match their CSV columns to our standard fields by string match.
  // 3. Match remaining CSV columns to our suggested fields to infer their type
  // 4. All remaining CSV columns are custom fields defaulted to string
  // 5. Add in our special composite Address field
  React.useEffect(() => {
    if (rawCsvHeaders && rawCsvHeaders.length) {
      // Create a lookup dict of { Csv header => index}
      const newCsvHeaderIndexLookup = {}
      rawCsvHeaders.forEach((rawCsvHeader, index) => {
        newCsvHeaderIndexLookup[rawCsvHeader] = index
      })

      // 1. Strip out our specific address fields
      const newHeadersWithoutSpecialFields = rawCsvHeaders.filter((header) => {
        return !BULK_IMPORT_CONSTANTS.SPECIAL_FIELDS_TO_STRIP.find(
          (stripField) => {
            return caseInsensitiveCompare(header, stripField)
          }
        )
      })

      let newMappedStandardFields = [...mappedStandardFields]

      // Now we build the list of fields from the csv headers. If its one of our standard fields (ie: price), then
      //   we match the csv field and use our predetermined metadata for the field (type, display name, etc).
      const newHeaders = newHeadersWithoutSpecialFields.map((header) => {
        const headerIndex = newCsvHeaderIndexLookup[header]

        // 2. Match their CSV columns to our standard fields by string match.
        const matchedStandardField = (
          withNewCustomFields
            ? BULK_IMPORT_CONSTANTS.NEW_STANDARD_FIELDS
            : BULK_IMPORT_CONSTANTS.STANDARD_FIELDS
        ).find((specialField) =>
          specialField.matches.find((sMatch) =>
            caseInsensitiveCompare(sMatch, header)
          )
        )

        if (matchedStandardField) {
          // Get rid of old matches on our standard fields, since there can only be one match
          newMappedStandardFields = newMappedStandardFields.map(
            (mappedStandardField) =>
              mappedStandardField.match === headerIndex
                ? { ...mappedStandardField, match: null, reserved: null }
                : mappedStandardField
          )
          // Find the index of the match
          const replaceIndex = newMappedStandardFields.findIndex(
            (mappedStandardField) =>
              caseInsensitiveCompare(
                mappedStandardField.displayName,
                matchedStandardField.displayName
              )
          )

          // If found, make it a match
          if (replaceIndex >= 0) {
            newMappedStandardFields[replaceIndex] = {
              ...newMappedStandardFields[replaceIndex],
              match: headerIndex,
              reserved: true,
            }
          }

          return {
            ...matchedStandardField,
            csvString: header,
            reserved: true,
            index: headerIndex,
          }
        }

        // 3. Match remaining CSV columns to our suggested fields to infer their type
        const matchedSuggestedField =
          BULK_IMPORT_CONSTANTS.SUGGESTED_FIELDS.find((suggestedField) =>
            suggestedField.matches.find((sMatch) =>
              caseInsensitiveCompare(sMatch, header)
            )
          )
        // If a csv field matches one of our suggested fields
        if (matchedSuggestedField) {
          return {
            ...matchedSuggestedField,
            csvString: header,
            index: headerIndex,
          }
        }

        // 4. All remaining CSV columns are custom fields defaulted to string
        return {
          displayName: header,
          csvString: header,
          fieldDataType: BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING,
          fieldType: BULK_IMPORT_CONSTANTS.FIELD_TYPES.SPACE,
          index: headerIndex,
        }
      })

      // Now, actually set everything to state
      // 5. Add in our special composite Address field
      setLoadedListingFieldsObj({
        [BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index]:
          BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS,
        ...Object.fromEntries(
          Object.values(newHeaders).map((v) => [v.index, v])
        ),
      })
      setUserMatchableCsvHeaders(Object.values(newHeaders))
      setCsvHeaderIndexLookup(newCsvHeaderIndexLookup)
      setMappedStandardFields(newMappedStandardFields)
    } else {
      setLoadedListingFieldsObj({
        [BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index]:
          BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS,
      })
      setUserMatchableCsvHeaders([])
      setCsvHeaderIndexLookup(null)
    }
  }, [rawCsvHeaders])

  // Validate the listings. We go through the raw data, validate by field type, and set whether or not there's an error.
  const validateListings = (orderedListingFieldsLocal) => {
    if (
      ((loadedListingsFormatted && loadedListingsFormatted.length) ||
        (validatedListings && validatedListings.length)) &&
      orderedListingFieldsLocal &&
      orderedListingFieldsLocal.length
    ) {
      // First, decide which listing data set to use.
      // If we've already validated before, use the validated data set. This contains the
      //   most up to date info.
      // If we haven't, then use the raw data.
      let listingData = {}
      if (validatedListings && validatedListings.length) {
        listingData = validatedListings
      } else {
        // annoyingly the two datasets have different formats, so here we make them identical
        listingData = loadedListingsFormatted.map((loadedListingFormatted) =>
          Object.fromEntries(
            Object.entries(loadedListingFormatted).map(([k, v]) => {
              // Type check here is because we have a special format for address, unfortunately
              return [k, typeof v === 'object' ? { ...v } : { csvValue: v }]
            })
          )
        )
      }

      // Go through each listing and validate it.
      const newListings = []
      listingData.forEach((listing, listingIndex) => {
        const newListing = {}

        // For each CSV field, get the value.
        orderedListingFieldsLocal.forEach((header) => {
          newListing[header.index] = {
            // Adding `originalIndex` to prevent a repeating index if a building has multiple spaces
            originalIndex: listingIndex,
            ...getValidatedFieldValue(
              header,
              listing,
              loadedListingsFormatted[listingIndex],
              csvHeaderIndexLookup
            ),
          }
        })
        newListings.push(newListing)
      })
      setValidatedListings(newListings)
    } else {
      setValidatedListings([])
    }
  }

  // When the listing fields are loaded as a dict, lets put them in an array and order them by
  //   their index, which is the order they apear in the csv.
  // Also flag any duplicates
  useEffect(() => {
    let newOrderedListingFields = null
    if (loadedListingFieldsObj && Object.keys(loadedListingFieldsObj).length) {
      // Reorder
      newOrderedListingFields = [...Object.values(loadedListingFieldsObj)]

      newOrderedListingFields.sort((a, b) => a.index - b.index)

      // Check and mark duplicate field names
      const listingFieldSetCounts = {}

      // Get a count of each header string
      newOrderedListingFields.forEach((listingField) => {
        if (listingFieldSetCounts[listingField.displayName]) {
          listingFieldSetCounts[listingField.displayName]++
        } else {
          listingFieldSetCounts[listingField.displayName] = 1
        }
      })
      // Mark any duplicates with an error
      newOrderedListingFields = newOrderedListingFields.map((listingField) => {
        if (listingFieldSetCounts[listingField.displayName] > 1) {
          return {
            ...listingField,
            error:
              'Duplicate field name. Please rename one of the duplicate fields.',
          }
        }
        if (
          !listingField.reserved &&
          caseInsensitiveFindInArray(
            withNewCustomFields
              ? BULK_IMPORT_CONSTANTS.NEW_RESERVED_FIELDS
              : BULK_IMPORT_CONSTANTS.RESERVED_FIELDS,
            'displayName',
            listingField.displayName
          )
        ) {
          return {
            ...listingField,
            error: 'Reserved field name. Please rename this field.',
          }
        }
        return { ...listingField, error: null }
      })
    }

    setOrderedListingFields(newOrderedListingFields)
    validateListings(newOrderedListingFields)
  }, [loadedListingFieldsObj])

  // After uploading the CSV, processing the headers, matching the fields, now we validate the listing data.
  useEffect(() => {
    validateListings(orderedListingFields)
  }, [loadedListingsFormatted])

  // When a header is removed by a user, remove it from our loadedListingFieldsObj
  useEffect(() => {
    if (removedHeaderIndices && loadedListingFieldsObj) {
      const newEntries = Object.entries(loadedListingFieldsObj).filter(
        ([, element]) => !removedHeaderIndices.has(element.index)
      )
      const newObject = Object.fromEntries(newEntries)
      setLoadedListingFieldsObj(newObject)
    }
  }, [removedHeaderIndices])

  return {
    rawCsvHeaders,
    setRawCsvHeaders,
    csvHeaderIndexLookup,
    loadedListingsFromCsv,
    setLoadedListingsFromCsv,
    loadedListingsFormatted,
    setLoadedListingsFormatted,
    mappedStandardFields,
    setMappedStandardFields,
    loadedListingFieldsObj,
    setLoadedListingFieldsObj,
    orderedListingFields,
    userMatchableCsvHeaders,
    setUserMatchableCsvHeaders,
    validatedListings,
    setValidatedListings,
    removedHeaderIndices,
    setRemovedHeaderIndices,
    uploadedCsvFile,
    setUploadedCsvFile,
  }
}

export { useBulkImport }
