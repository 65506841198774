import React from 'react'
import { Link, Menu, MenuItem, Paper } from '@material-ui/core'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {
  CircleIconButton,
  Logo,
  Typography,
  UserAvatar,
} from '~/legacy/components'
import {
  AdminIcon,
  ClientIcon,
  LogOutIcon,
  SettingsIcon,
  SurveyIcon,
  SurveysIcon,
} from '~/legacy/components/svgs'
import { LEASE_ADMIN, LEASE_LOGIN, LEASE_SETTINGS } from '~/legacy/consts'
import { isBrokerAdminByTypeAndRole, useIsLeaseupLogo } from '~/legacy/utils'

const TopNavMenuItem = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <MenuItem
      ref={ref}
      className="w-[240px] h-[38px] p-3 leading-[14px]"
      disableGutters
      {...props}
    >
      <Typography
        variant="boldText"
        className="flex items-center gap-3 font-semibold"
      >
        {children}
      </Typography>
    </MenuItem>
  )
})

function TopNav() {
  const isLeaseUpLogo = useIsLeaseupLogo()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((store) => store.user)

  const profilePopupState = usePopupState({
    variant: 'popover',
    popupId: 'profile-menu',
  })

  return (
    <Paper
      className="flex items-center bg-white border-0 border-b border-solid border-[#e8e8e8] h-[60px] pl-8"
      elevation={0}
    >
      <div
        className={clsx(
          'flex h-full overflow-hidden min',
          isLeaseUpLogo
            ? 'min-w-[120px] max-w-[120px]'
            : 'min-w-[158px] max-w-[158px]'
        )}
      >
        <Logo />
      </div>
      {!user.isAnonymous && (
        <div
          className={clsx(
            'flex justify-end mr-6 ml-auto',
            isLeaseUpLogo
              ? 'min-w-[120px] max-w-[120px]'
              : 'min-w-[158px] max-w-[158px]'
          )}
        >
          <CircleIconButton
            {...bindTrigger(profilePopupState)}
            active={profilePopupState.isOpen}
            icon={<UserAvatar user={user} />}
          />
        </div>
      )}
      {user.isAnonymous && (
        <Link
          className="mr-8 ml-auto cursor-pointer hover:text-inherit"
          color="inherit"
          onClick={() => history.push(LEASE_LOGIN)}
          underline="none"
          variant="h3"
        >
          Login
        </Link>
      )}
      <Menu
        {...bindMenu(profilePopupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        elevation={1}
        classes={{ list: 'py-0 pb-2' }}
      >
        <div className="px-3 py-2 pt-5 font-medium font-med text-[#666] leading-3">
          My Stuff
        </div>

        <a href="/my-stuff/projects" className="no-underline text-inherit">
          <TopNavMenuItem>
            <SurveysIcon />
            Projects
          </TopNavMenuItem>
        </a>

        <a href="/my-stuff/surveys" className="no-underline text-inherit">
          <TopNavMenuItem>
            <SurveyIcon />
            Surveys
          </TopNavMenuItem>
        </a>

        <a href="/my-stuff/clients" className="no-underline text-inherit">
          <TopNavMenuItem>
            <ClientIcon />
            Clients
          </TopNavMenuItem>
        </a>

        <div className="px-3 py-2 pt-5 font-medium font-med text-[#666] leading-3">
          Account
        </div>

        <TopNavMenuItem
          onClick={() => {
            profilePopupState.close()
            history.push(LEASE_SETTINGS)
          }}
        >
          <SettingsIcon />
          Settings
        </TopNavMenuItem>

        {isBrokerAdminByTypeAndRole(user.userType, user.user_role) && (
          <TopNavMenuItem
            onClick={() => {
              profilePopupState.close()
              history.push(LEASE_ADMIN)
            }}
          >
            <AdminIcon />
            Admin
          </TopNavMenuItem>
        )}

        <TopNavMenuItem
          onClick={() => {
            profilePopupState.close()
            dispatch({ type: 'LOGOUT' })
            history.push(LEASE_LOGIN)
          }}
        >
          <LogOutIcon />
          Logout
        </TopNavMenuItem>
      </Menu>
    </Paper>
  )
}

export default TopNav
