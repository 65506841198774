import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import {
  Input,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles, lighten } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { BULK_IMPORT_CONSTANTS, BULK_IMPORT_HELPERS } from '~/legacy/utils'
import {
  ClearTextInputSvg,
  SimpleAutocomplete,
  AutocompleteChip,
  Typography,
  TextInput,
} from '~/legacy/components'
import { EditIcon, LinkExternalIcon } from '~/legacy/components/svgs'
import { SquareIconButton } from '~/legacy/components/buttons'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: (props) => props.styleOverrides.container.padding,
    cursor: (props) => props.styleOverrides.container.cursor,
  },
  chipRoot: {
    backgroundColor: lighten(theme.palette.primary.main, 0.85),
  },
  deleteIconChip: {
    color: theme.palette.primary.main,
    margin: '0 4px 0 -4px',
    height: '24px',
    width: '24px',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  value: {
    flex: 1,
    padding: '0 5px',
    cursor: (props) => !props.viewOnly && 'pointer',
  },
  valueClearInputIcon: {
    height: '16px',
    cursor: 'pointer',
    marginRight: '-5px',
  },
  valueInput: {
    flex: 1,
    marginTop: '1px',
    marginLeft: '5px',
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: '5px 10px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '5px 10px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0',
    },
  },
  valueHovered: {
    '&:hover': {
      backgroundColor: (props) => {
        return !props.viewOnly && props.styleOverrides.hoverBackgroundColor
      },
    },
  },
  valueLight: {
    color: '#666666',
  },
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
    },
  },
  noBorder: {
    border: 'none',
  },
  autocompleteChipLabel: {
    paddingRight: '8px',
  },
  multilineTextInput: {
    padding: '16px 14px',
  },
  multilineTextForm: {
    height: '100%',
    '& .MuiTextField-root': {
      height: '100%',
    },
    '& .MuiInputBase-input': {
      lineHeight: '22px',
      letterSpacing: '0.1px',
    },
  },
  multilineTextInputContainer: {
    width: '480px',
    minHeight: '130px',
    height: 'fit-content',
    padding: '16px',
  },
}))

const getDefaultStyles = () => ({
  hoverBackgroundColor: '#e0e0e0',
  container: {},
})

const OverflowTip = ({ title, children }) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef()
  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    )
  }, [])
  return (
    <Tooltip title={title} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}

// supported classesIn:
// value, valueInput
export default function InlineEditableValue({
  classesIn,
  styleOverrides = {},
  onClick,
  options = undefined,
  placeholder,
  autoFocus = true,
  type,
  value,
  viewOnly = false,
  textContentVariant = 'bodyText',
  fieldDataTypeId = BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.STRING.id,
  displayValueOverride = undefined,
  prepareNewValue = undefined,
  formatDisplayValue = undefined,
  hoverOnContainer = false,
  updateValueApi = () => Promise.resolve({}),
  updateValueApiCallback = () => {},
  updateValueCallback = () => {},
  allowParentClicks = false,
  field = {},
  extraProps = {},
}) {
  const styleOverridesFinal = {
    ...getDefaultStyles(viewOnly),
    ...styleOverrides,
  }
  const classes = useStyles({ viewOnly, styleOverrides: styleOverridesFinal })
  // Choose how to format the display of the value based on the type of field we are displaying
  let formatDisplayValueFunc = formatDisplayValue
  // Use the provided format function if provided, else use a default
  if (!formatDisplayValue) {
    formatDisplayValueFunc =
      BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES_LOOKUP[fieldDataTypeId].formatter
  }

  // Choose how to prepare a new selected value to be stored based on the field type
  let prepareNewValueFunc = prepareNewValue
  if (!prepareNewValueFunc) {
    prepareNewValueFunc = (inputValue) => inputValue

    if (BULK_IMPORT_CONSTANTS.FIELD_DATA_TYPES.DATE.id === fieldDataTypeId) {
      prepareNewValueFunc = BULK_IMPORT_HELPERS.prepareDateForAPISave
    }
  }

  const inputRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const anchorEl = React.useRef(null)

  // The most recently API saved value
  // const preparedValue = prepareNewValueFunc(value)
  const [savedValue, setSavedValue] = useState(value)
  // The most recently updated value, not necessarily persisted to API yet
  const [newValue, setNewValue] = useState(value)
  const [displayValue, setDisplayValue] = useState(
    displayValueOverride !== undefined
      ? displayValueOverride
      : formatDisplayValueFunc(value)
  )

  const setNewValueWithDisplay = (newerValue) => {
    setDisplayValue(formatDisplayValueFunc(newerValue))
    setNewValue(newerValue)
  }

  const formatAndSetNewValue = (newestValue) => {
    const newPreparedValue = prepareNewValueFunc(newestValue)
    setNewValueWithDisplay(newPreparedValue)
    return newPreparedValue
  }

  // Whenever the parent custom field changes, reset the value state
  useEffect(() => {
    setSavedValue(value)
    setNewValueWithDisplay(value)
  }, [value])

  // Whenever the field type changes, update the display value
  useEffect(() => {
    setNewValueWithDisplay(value)
  }, [fieldDataTypeId])

  const isMulitselect = !!['multi-select'].includes(type)
  // Multiselect being edited, or not being edited and not empty
  const useMultiselect = !!(isMulitselect && (isEditing || newValue))

  const isAddress = !!['address'].includes(type)
  const isMultilineText = !!['multiline-text'].includes(type)

  // Address stuff
  let stateAndZip
  let secondLine
  let address
  let state
  let zipcode
  let city
  if (isAddress && displayValue) {
    ;({ address, state, zipcode, city } = displayValue)
    stateAndZip = [state, zipcode].filter((e) => !!e).join(' ')
    secondLine = [city, stateAndZip].filter((e) => !!e).join(', ')
  }

  const updateValue = (newerValue, endEditingCallback) => {
    // 2. Now update the value via API
    const updatePromise = updateValueApi(newerValue)

    // 3. Handle the api response
    updatePromise.then((data) => {
      if (!data) {
        setNewValueWithDisplay(savedValue)
      }
      updateValueApiCallback(data, newerValue)

      // 4. Do the callback always
      endEditingCallback()
      updateValueCallback()
    })
  }

  const prepareAndUpdateValue = (
    newestValue,
    endEditingCallback = () => {}
  ) => {
    // 1. First prepare the new value to save
    const newPreparedValue = formatAndSetNewValue(newestValue)
    updateValue(newPreparedValue, endEditingCallback)
  }

  const textContentsOnClick = (event) => {
    event.stopPropagation()
    if (!viewOnly) {
      setIsEditing(true)
      onClick()
    }
  }

  // Text content to show when not editing
  const TextContents = () => {
    return (
      <Typography
        className={clsx(
          classes.value,
          !newValue && !displayValue && classes.valueLight,
          classesIn.value,
          !hoverOnContainer && !isMultilineText && classes.valueHovered,
          'group relative'
        )}
        component="span"
        noWrap
        variant={textContentVariant}
        onClick={textContentsOnClick}
      >
        {isAddress && displayValue ? (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {!!address && <Typography noWrap>{address}</Typography>}
              {!!secondLine && <Typography noWrap>{secondLine}</Typography>}
            </div>

            {extraProps.isBulkEdit ? (
              <div className="group-hover:flex hidden absolute right-0 top-1 bg-white size-7 items-center justify-center rounded">
                <Link
                  to={extraProps.buildingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline text-inherit"
                >
                  <SquareIconButton className="hover:bg-[#f3f3f3] elevation-1">
                    <LinkExternalIcon />
                  </SquareIconButton>
                </Link>
              </div>
            ) : null}
          </>
        ) : (
          displayValue || newValue || placeholder
        )}
      </Typography>
    )
  }

  // If we are showing text for an address, don't include a tooltip
  const TextContentsFinal =
    isAddress || isMultilineText ? (
      <TextContents />
    ) : (
      <OverflowTip title={displayValue || newValue || placeholder}>
        <TextContents />
      </OverflowTip>
    )

  const showStaticText =
    (!isEditing && !useMultiselect && (!isAddress || displayValue)) ||
    type === 'date'

  return (
    <ClickAwayListener onClickAway={() => setIsEditing(false)}>
      <div
        ref={anchorEl}
        className={clsx(
          classes.container,
          classesIn.container,
          isEditing ? classesIn.editingContainer : '',
          // For multiline text we don't background color on hover
          hoverOnContainer &&
            showStaticText &&
            !isMultilineText &&
            classes.valueHovered,
          'group relative'
        )}
        onClick={
          hoverOnContainer && showStaticText
            ? textContentsOnClick
            : (event) => {
                if (!allowParentClicks) {
                  event.stopPropagation()
                }
              }
        }
      >
        {useMultiselect && (
          <Autocomplete
            fullWidth
            disableClearable
            freeSolo
            multiple
            disableListWrap
            className={classes.autocomplete}
            onChange={(event, newVMulitselectValues) => {
              prepareAndUpdateValue(newVMulitselectValues, () =>
                setIsEditing(false)
              )
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: !isEditing,
                  autoFocus,
                }}
              />
            )}
            renderTags={(rValue, getTagProps) =>
              rValue.map((option, index) => (
                <AutocompleteChip
                  key={option}
                  option={option}
                  chipProps={getTagProps({ index })}
                />
              ))
            }
            value={newValue || []}
          />
        )}
        {isAddress && (isEditing || !displayValue) && (
          <SimpleAutocomplete
            onBlur={() => setIsEditing(false)}
            onChange={(newAddress, newAddressParts, newAutocompleteValue) => {
              prepareAndUpdateValue(
                {
                  GOOGLE_ADDRESS: newAddressParts,
                  GOOGLE_AUTOCOMPLETE_RESULTS: newAutocompleteValue,
                },
                () => setIsEditing(false)
              )
            }}
            placeholder="Enter an address"
            initialValue={newValue}
            autoFocusAddressInput={isEditing}
          />
        )}
        {!!showStaticText && TextContentsFinal}
        {isEditing && ['multiline-text'].includes(type) && (
          <>
            {TextContentsFinal}
            <Popover
              style={{ marginTop: '4px' }}
              anchorEl={anchorEl.current}
              elevation={1}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={() => {
                if (newValue !== savedValue) {
                  updateValue(newValue, () => setIsEditing(false))
                } else {
                  setIsEditing(false)
                }
              }}
              open={isEditing}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.multilineTextInputContainer}>
                <TextInput
                  FormControlProps={{
                    classes: { root: classes.multilineTextForm },
                  }}
                  multiline
                  rows={3}
                  rowsMax={Infinity}
                  label={field.label || 'Space Notes'}
                  autoFocus
                  fullWidth
                  value={newValue || ''}
                  onKeyPress={() => {}}
                  onChange={(event) => {
                    formatAndSetNewValue(event.target.value)
                  }}
                  InputProps={{
                    classes: { root: classes.multilineTextInput },
                  }}
                />
              </div>
            </Popover>
          </>
        )}
        {isEditing && ['text', 'number'].includes(type) && (
          <Input
            inputRef={inputRef}
            autoFocus={autoFocus}
            className={clsx(classes.valueInput, classesIn.valueInput)}
            endAdornment={
              <InputAdornment position="end">
                <ClearTextInputSvg
                  className={clsx(
                    classes.valueClearInputIcon,
                    classesIn.valueClearInputIcon
                  )}
                  onClick={() =>
                    setNewValueWithDisplay(type === 'number' ? null : '')
                  }
                  onMouseDown={(event) => event.preventDefault()}
                />
              </InputAdornment>
            }
            margin="none"
            onChange={(event) => {
              formatAndSetNewValue(event.target.value)
            }}
            onBlur={() => {
              if (newValue !== savedValue) {
                updateValue(newValue, () => setIsEditing(false))
              } else {
                setIsEditing(false)
              }
            }}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                inputRef.current.blur()
              } else if (event.key === 'Escape') {
                setNewValueWithDisplay(savedValue)
                setIsEditing(false)
              }
            }}
            placeholder={newValue ? '' : placeholder}
            size="small"
            type={type}
            value={
              newValue || (type === 'number' && newValue === 0) ? newValue : ''
            }
          />
        )}
        {['date'].includes(type) && (
          <Popover
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={() => setIsEditing(false)}
            open={isEditing}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                disableToolbar
                format="yyyy-MM-dd"
                fullWidth
                id="date-picker-inline"
                onChange={(newDate) => {
                  const formattedDate = prepareNewValueFunc(newDate)
                  updateValue(formattedDate, () => {
                    setIsEditing(false)
                  })
                }}
                variant="static"
                value={newValue}
              />
            </MuiPickersUtilsProvider>
          </Popover>
        )}

        {/* Edit icon popover for the multiline text edit hover */}
        {isMultilineText && (
          <div className="group-hover:flex hidden absolute right-0 top-1 bg-white size-7 items-center justify-center rounded">
            <SquareIconButton className="hover:bg-[#f3f3f3] elevation-1">
              <EditIcon />
            </SquareIconButton>
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}
