import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react'
import {
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableBody,
} from '@material-ui/core'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import clsx from 'clsx'
import _ from 'lodash'
import { DndProvider, useDrag, useDrop } from 'react-dnd'

import { withStyles } from '@material-ui/core/styles'
import {
  getPlaceholderTextByDisplayName,
  useWindowDimensions,
  getModelFieldNameByDisplayName,
  getIconByDataTypeId,
  BULK_IMPORT_CONSTANTS,
  BACKEND_FOR_DND,
  arrayMove,
} from '~/legacy/utils'
import {
  TableHeaderCell,
  TableHeaderText,
  TableHeaderRow,
  TableRow,
  TableContentCell,
  EditableCell,
} from '~/legacy/components/tableComponents'
import {
  ModalComponent,
  Typography,
  Button,
  PageStickyNavBar,
  TextChevronDown,
  MODALS,
  Modal,
  SquareIconButton,
  useFieldMenu,
  useEditSpaceMenu,
  TextChevronUp,
  DragIcon,
} from '~/legacy/components'
import { useAddSpaceToBuildingMenu } from '~/legacy/components/menus/AddSpaceToBuildingMenu'
import CustomFieldMenu from '../customFields/CustomFieldMenu'
import FullScreenTitle from './FullScreenTitle'
import { AddCustomFieldTableRow } from './shared/AddCustomFieldTableRow'

/* eslint-disable react/no-array-index-key */

const useStyles = makeStyles({
  modal: {
    width: '100vw',
    overflow: 'hidden',
    '&:first-child': {
      padding: '0',
    },
  },
  sticky: {
    position: 'sticky',
    left: 0,
  },
  pagePadding: {
    padding: (props) => `0 ${props.pagePadding}px`,
  },
  body: {
    width: '100%',
    marginTop: '60px', // for the header
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  title: {
    margin: (props) => `70px ${props.pagePadding}px 0 ${props.pagePadding}px`,
    paddingBottom: '32px',
    borderBottom: '1px solid #e0e0e0',
  },
  tableAreaHeader: {
    justifyContent: 'space-between',
  },
  tableAreaHeaderButtons: {
    '& button:first-child': {
      marginRight: '8px',
    },
  },
  tableAreaHeaderButton: {
    height: '36px',
    minWidth: 'unset',
    padding: '11px 16px',
    textTransform: 'none',
  },
  tableAreaHeaderButtonIcon: {
    marginLeft: 0,
  },
  tableContainer: {
    overflowX: 'visible',
    width: 'fit-content',
    marginBottom: '80px',
  },
  metadataTable: {
    minWidth: '640px',
    tableLayout: 'fixed',
    borderRadius: '4px',
    borderCollapse: 'separate',
    border: '1px solid #E0E0E0',

    '& tr': {
      '& th:not(:last-child), td:not(:last-child)': {
        borderRight: '1px solid #e0e0e0',
      },
      '& th, td': {
        width: '220px',
      },
    },

    // Table border radiuses
    '& tr:first-child th:first-child': {
      borderTopLeftRadius: '4px',
    },
    '& tr:first-child th:last-child': {
      borderTopRightRadius: '4px',
    },
    '& tr:last-child td:first-child': {
      borderBottomLeftRadius: '4px',
    },
    '& tr:last-child td:last-child': {
      borderBottomRightRadius: '4px',
    },
  },
  spaceColumnHeader: {
    color: '#111111',
    textTransform: 'uppercase',
  },
  fakeTableCell: {
    padding: '0 22px',
  },
  metadataKeyCell: {
    paddingLeft: '10px',
    paddingRight: '22px',
  },
  spaceNotesBreaker: {
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    '&:last-child': {
      paddingRight: 0,
    },
    backgroundColor: '#F9F9F9',
  },
  spaceNotesBreakerRow: {
    height: '24px',
  },
  metadataKey: {
    display: 'flex',
    alignItems: 'center',
  },
  metadataKeyText: {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editableCell: {
    padding: (props) => (props.isSpaceNotes ? '0 48px 0 12px' : '0 12px'),
  },
  deleteFieldMenuSection: {
    marginTop: '20px',
  },
  deleteFieldMenuTitle: {
    marginLeft: '12px',
    marginBottom: '6px',
  },
  menuItem: {
    paddingLeft: '12px',
    paddingRight: '12px',
    alignItems: 'center',
  },
  emptyStateContainer: {
    background: '#e0e0e0',
    color: '#666666',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '30px 0',
  },
  emptyStateHeader: {
    marginBottom: '4px',
  },
  dragIcon: {
    cursor: 'grab',
  },
})

const useEditableCellStyles = makeStyles({
  container: {
    height: '36px',
    paddingLeft: '12px',
    cursor: 'pointer',
    marginTop: (props) => props.isKey && '1px',
  },
  value: {
    padding: 0,
  },
  valueInput: {
    margin: 0,
    marginTop: '1px',
  },
  valueClearInputIcon: {
    color: '#e0e0e0',
    '&:hover': {
      color: '#e0e0e0',
    },
  },
  addFieldMenu: {
    marginTop: '8px',
  },
})

const DraggableMetadataRow = ({
  index,
  moveField,
  saveFieldOrder,
  ...props
}) => {
  const ref = useRef(null)
  const previewRef = useRef(null)
  const ItemTypes = {
    CARD: 'card',
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      moveField(dragIndex, hoverIndex)
      // eslint-disable-next-line
      item.index = hoverIndex
    },
    drop: () => {
      saveFieldOrder()
    },
  })
  const opacity = isDragging ? 0 : 1
  drag(ref)
  drop(previewRef)
  preview(previewRef)

  return (
    <MetadataRow
      dndRefs={{ ref, previewRef }}
      handlerId={handlerId}
      opacity={opacity}
      draggable
      {...props}
    />
  )
}

function MetadataRow({
  dndRefs = {},
  handlerId,
  opacity,
  label,
  field,
  values,
  surveySpaces,
  updateListing,
  createCustomListingField,
  updateListingCustomField,
  deleteListingCustomField,
  renameListingCustomFields,
  setFieldMenuAnchorEl,
  buildingName,
  draggable = false,
  uniqueFieldNames,
}) {
  const classes = useStyles({ isSpaceNotes: label === 'Space Notes' })
  const editableCellKeyClasses = useEditableCellStyles({ isKey: true })
  const editableCellClasses = useEditableCellStyles()
  const modelFieldName = useMemo(
    () => getModelFieldNameByDisplayName(label),
    [label]
  )
  const isCustomField = !modelFieldName
  const fieldType = field.dataType
  const IconClass = getIconByDataTypeId(fieldType.id)
  const placeholderText = getPlaceholderTextByDisplayName(label)

  const openMenu = (event) =>
    setFieldMenuAnchorEl({
      anchor: event.currentTarget,
      fieldName: label,
      buildingName,
      modelFieldName,
      fieldType: fieldType.id,
    })

  return (
    <TableRow
      ref={dndRefs.previewRef}
      data-handler-id={handlerId}
      style={{
        opacity,
      }}
    >
      <TableContentCell classes={{ tableCell: classes.metadataKeyCell }}>
        <div className={classes.metadataKey}>
          <div
            ref={dndRefs.ref}
            style={{ display: 'flex', paddingRight: '6px' }}
          >
            {draggable ? (
              <DragIcon className={classes.dragIcon} />
            ) : (
              <div style={{ width: '24px' }} />
            )}
          </div>
          <SquareIconButton onClick={openMenu}>
            <IconClass />
          </SquareIconButton>
          {isCustomField ? (
            <EditableCell
              value={label}
              updateValue={() => {}}
              updateValueApi={(newName) =>
                new Promise((resolve) => {
                  if (newName && !uniqueFieldNames.has(newName)) {
                    renameListingCustomFields(
                      surveySpaces
                        .map((surveySpace) => {
                          const fieldLocal =
                            surveySpace.listing.custom_fields.find(
                              (cf) => cf.name === label
                            )
                          return fieldLocal ? fieldLocal.id : null
                        })
                        .filter((id) => id), // filter out nulls
                      newName
                    )
                    resolve(newName)
                  } else {
                    resolve()
                  }
                })
              }
              classesIn={editableCellKeyClasses}
              styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
              textContentVariant="bodyBold"
            />
          ) : (
            <Typography variant="bodyBold" className={classes.metadataKeyText}>
              {label}
            </Typography>
          )}
        </div>
      </TableContentCell>
      {values.map((fieldValue, vIndex) => {
        const { value } = fieldValue

        const updateValue = (newValue) => {
          const { listing } = surveySpaces[vIndex]
          console.log({
            value,
            newValue,
            fieldValue,
            modelFieldName,
            listing,
            surveySpaces,
            label,
            uniqueFieldNames,
          })

          if (modelFieldName) {
            // updating a regular field
            updateListing(listing.id, { [modelFieldName]: newValue })
          } else {
            // updating a custom field
            const fieldLocal = listing.custom_fields.find(
              (customField) => customField.name === label
            )

            if (fieldLocal) {
              // update or delete existing field
              if (newValue !== '') {
                updateListingCustomField(listing.id, {
                  ...fieldLocal,
                  custom_field: {
                    ...fieldLocal.custom_field,
                    value: newValue,
                  },
                })
              } else {
                deleteListingCustomField(listing.id, fieldLocal.id)
              }
            } else if (label && uniqueFieldNames.has(label)) {
              // create the custom field for this specific listing
              createCustomListingField(listing.id, label, newValue)
            }
          }
        }
        return (
          <TableContentCell
            key={`${label}:${value}:${vIndex}`}
            classes={{ tableCell: classes.editableCell }}
          >
            <EditableCell
              value={value}
              updateValue={updateValue}
              updateValueApi={(newValue) =>
                new Promise((resolve) => {
                  updateValue(newValue)
                  resolve(newValue)
                })
              }
              fieldType={fieldType.id}
              classesIn={editableCellClasses}
              styleOverrides={{ hoverBackgroundColor: '#f3f3f3' }}
              placeholder={placeholderText}
            />
          </TableContentCell>
        )
      })}
    </TableRow>
  )
}

const SpaceHeader = withStyles({
  tableCell: {
    padding: '0 22px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
})(
  ({
    classes,
    surveyBuilding,
    buildingName,
    space,
    updateSpaceName,
    deleteSurveyListing,
  }) => {
    const menuState = usePopupState({
      variant: 'popover',
      popupId: `space-${space.id}-menu`,
    })
    const surveyListingId = useMemo(
      () =>
        surveyBuilding.survey_listings.find((sl) => sl.listing.id === space.id)
          .id,
      [surveyBuilding, space]
    )
    const {
      EditSpaceMenuComponent,
      ConfirmRemoveSpaceFromSurveyModalComponent,
    } = useEditSpaceMenu({
      menuState,
      surveyListingId,
      spaceName: space.address2,
      updateSpaceName,
      deleteSurveyListing,
      buildingName,
    })
    return (
      <TableHeaderCell classes={{ tableCell: classes.tableCell }}>
        <div className={classes.container} {...bindTrigger(menuState)}>
          <Typography variant="tableHeader">
            {space.address2 || 'ADD NAME'}
          </Typography>
          {menuState.isOpen ? <TextChevronUp /> : <TextChevronDown />}
        </div>
        {EditSpaceMenuComponent}
        {ConfirmRemoveSpaceFromSurveyModalComponent}
      </TableHeaderCell>
    )
  }
)

const _createSurveyListingFieldValue = (
  id,
  value,
  formattedValue,
  surveyListingId
) => ({ id, value, formattedValue, surveyListingId })

export default function EditSpacesModal({
  surveySpaces,
  buildingName,
  surveyId,
  surveyBuilding,
  mutate,
  deleteSurveyListing,
  updateListing,
  createCustomListingField,
  createCustomListingFields,
  updateListingCustomField,
  deleteListingCustomField,
  deleteListingCustomFields,
  renameListingCustomFields,
  changeListingCustomFieldDataType,
  clearSpacesField,
  createSurveyListing,
  setSpacesFieldOrder,
  copySpacesFromSurvey,
  ModalComponentProps,
}) {
  // raw field order off of the survey building
  const orderedFields = _.get(surveyBuilding, 'ordered_fields', null)
  // local field order state - we update this as we drag but before we drop
  const [newOrderedFields, setNewOrderedFields] = useState(orderedFields)
  // prevent null access elsewhere
  const safeOrderedFields = newOrderedFields || []
  const uniqueFieldNames = new Set(safeOrderedFields.map((of) => of.name))

  // Dict of the field name to the field metadata and and the values for the survey spaces
  // [{[name]: {name, dataType, reserved, values: [{id, value, formattedValue, surveyListingId}, {id, value, formattedValue, surveyListingId}, ...]}, ...]
  // ie: [ 'Lease Type': {name: 'Lease Type', data_type: 1, reserved: true, values: [{id: -1, value: 'long', formattedValue: 'long', surveyListingId: 1324}, ...]}, ...]
  const rowsByFieldName = safeOrderedFields.map((fo) => {
    const surveySpaceFields = []
    surveySpaces.forEach((surveySpace) => {
      const spaceField = _.get(
        _.get(surveySpace.listing, 'metadataLookup', {}),
        fo.name,
        {}
      )
      // {id, value, formattedValue, surveyListingId}
      surveySpaceFields.push(
        _createSurveyListingFieldValue(
          spaceField.id,
          spaceField.value,
          spaceField.formattedValue,
          surveySpace.id
        )
      )
    })
    return { field: fo, values: surveySpaceFields }
  })
  // Get the row for the space notes since they are not part of the metadata
  const spaceNotesFields = surveySpaces.map((ss) =>
    _createSurveyListingFieldValue(
      BULK_IMPORT_CONSTANTS.FIELDS.BUILDING_DESCRIPTION.id,
      ss.listing.description,
      ss.listing.description,
      ss.id
    )
  )
  const spaceIds = surveySpaces.map((s) => s.listing.id)

  const [showAddFromAnotherModal, setShowAddFromAnotherModal] = useState(false)
  const [addingNewField, setAddingNewField] = useState(false)
  const [addingListing, setAddingListing] = useState(false)

  const { windowWidth } = useWindowDimensions()
  const pagePadding = useMemo(() => {
    const tableWidth = Math.max((surveySpaces.length + 1) * 220 + 2, 640)

    return (windowWidth - tableWidth) / 2
  }, [surveySpaces.length, windowWidth])

  const classes = useStyles({ pagePadding: Math.max(pagePadding, 32) })
  const editableCellClasses = useEditableCellStyles()

  const menuState = usePopupState({
    variant: 'popover',
    popupId: 'add-field-menu',
  })

  const {
    setAnchorMenuEl: setFieldMenuAnchorEl,
    SpaceFieldMenuComponent,
    DeleteSpaceFieldModalComponent,
    ConfirmChangeFieldTypeModalComponent,
  } = useFieldMenu({
    isBuildingField: false,
    deleteField: ({ modelFieldName, spaceFieldName }) => {
      if (modelFieldName) {
        // Delete a regular field
        return clearSpacesField(modelFieldName)
      }
      // Delete a custom field
      return deleteListingCustomFields(spaceFieldName)
    },
    changeCustomFieldDataType: (fieldName, newFieldType) => {
      const currentValues = rowsByFieldName.find(
        (row) => row.field.name === fieldName
      ).values
      const validatedValuesMap = currentValues
        .map((val, index) => {
          return { [spaceIds[index]]: newFieldType.validator(val.value).value }
        })
        .reduce((accumulator, map) => ({ ...accumulator, ...map }))
      changeListingCustomFieldDataType(
        spaceIds,
        fieldName,
        validatedValuesMap,
        newFieldType.id
      )
    },
  })

  useEffect(() => {
    setNewOrderedFields(orderedFields)
  }, [orderedFields])

  // Save the field order to backend
  const saveFieldOrder = useCallback(async () => {
    const toSave = {}
    rowsByFieldName.forEach((fieldRow) => {
      fieldRow.values.forEach((surveyListingField) => {
        if (!toSave[surveyListingField.surveyListingId]) {
          toSave[surveyListingField.surveyListingId] = []
        }
        toSave[surveyListingField.surveyListingId].push(surveyListingField.id)
      })
    })

    setSpacesFieldOrder({ fieldOrder: toSave })
  }, [setSpacesFieldOrder, rowsByFieldName])

  // Move the field on drag, local order
  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      setNewOrderedFields((a) => arrayMove(a, dragIndex, hoverIndex))
    },
    [setNewOrderedFields]
  )

  // Get the number of columns we have total in the table to help format the divider row
  // -1 is any unique number that doesn't conflict with survey listing id
  const dividerColumns = [-1]
  if (surveySpaces && surveySpaces.length) {
    surveySpaces.forEach((ss) => dividerColumns.push(ss.id))
  }
  // TODO: unify this with the bulk import defs
  const mockSpaceNotesField = {
    name: BULK_IMPORT_CONSTANTS.FIELDS.SPACE_NOTES.displayName,
    reserved: BULK_IMPORT_CONSTANTS.FIELDS.SPACE_NOTES.reserved,
    dataType: BULK_IMPORT_CONSTANTS.FIELDS.SPACE_NOTES.fieldDataType,
  }

  const {
    setAnchorMenuEl,
    AddSpaceToBuildingMenuComponent,
    AddExistingSpacesToBuildingModalComponent,
  } = useAddSpaceToBuildingMenu({
    addNewSpace: async (surveyBuildingId) => {
      setAddingListing(true)
      try {
        await createSurveyListing({ surveyBuildingId })
      } catch {
        // supress
      } finally {
        setAddingListing(false)
      }
    },
    copySpacesFromSurvey,
  })

  const fieldCountText =
    safeOrderedFields.length === 1
      ? `1 Field`
      : `${safeOrderedFields.length} Fields`
  const spaceCountText =
    surveySpaces.length === 1 ? `1 Space` : `${surveySpaces.length} Spaces`

  return (
    <ModalComponent
      classesIn={{ dialogContent: classes.modal }}
      fullScreen
      {...ModalComponentProps}
    >
      <FullScreenTitle
        title={buildingName}
        onClose={ModalComponentProps.onClose}
      />
      <div className={classes.body}>
        <div className={classes.sticky}>
          <Typography className={clsx(classes.title)} variant="pageTitle">
            Space Details
          </Typography>
        </div>
        <PageStickyNavBar
          classes={{
            root: classes.pagePadding,
            content: classes.tableAreaHeader,
          }}
          twoAxis
        >
          <Typography variant="boldText">{`${fieldCountText}, ${spaceCountText}`}</Typography>
          <div className={classes.tableAreaHeaderButtons}>
            <Button
              color="primary"
              className={classes.tableAreaHeaderButton}
              disabled={addingListing}
              loading={addingListing}
              onClick={async (event) => {
                setAnchorMenuEl({
                  anchor: event.currentTarget,
                  surveyBuilding,
                  surveyId: parseInt(surveyId, 10),
                })
              }}
            >
              Add a Space
            </Button>
            <Button
              {...bindTrigger(menuState)}
              className={classes.tableAreaHeaderButton}
              classes={{ endIcon: classes.tableAreaHeaderButtonIcon }}
              color="primary"
              endIcon={<TextChevronDown />}
            >
              Add a Field
            </Button>
            <CustomFieldMenu
              classes={{ paper: classes.addFieldMenu }}
              {...bindMenu(menuState)}
              onClickCreateNew={() => {
                setAddingNewField(true)
                menuState.close()
              }}
              onClickAddAnother={() => {
                setShowAddFromAnotherModal(true)
                menuState.close()
              }}
              type="listing"
              elevation={2}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
            />
          </div>
        </PageStickyNavBar>
        {surveySpaces.length > 0 && (
          <TableContainer
            className={clsx(classes.tableContainer, classes.pagePadding)}
          >
            <Table size="small" className={classes.metadataTable}>
              <TableHead>
                <TableHeaderRow>
                  <TableHeaderCell>
                    <TableHeaderText>FIELD NAME</TableHeaderText>
                  </TableHeaderCell>
                  {surveySpaces.map((surveySpace) => (
                    <SpaceHeader
                      key={surveySpace.listing.id}
                      classes={{ container: classes.spaceColumnHeader }}
                      surveyBuilding={surveyBuilding}
                      buildingName={buildingName}
                      space={surveySpace.listing}
                      updateSpaceName={(newName) =>
                        updateListing(surveySpace.listing.id, {
                          address2: newName,
                        })
                      }
                      deleteSurveyListing={deleteSurveyListing}
                    />
                  ))}
                </TableHeaderRow>
              </TableHead>
              <TableBody>
                <MetadataRow
                  label="Space Notes"
                  surveySpaces={surveySpaces}
                  values={spaceNotesFields}
                  field={mockSpaceNotesField}
                  updateListing={updateListing}
                  createCustomListingField={createCustomListingField}
                  updateListingCustomField={updateListingCustomField}
                  deleteListingCustomField={deleteListingCustomField}
                  renameListingCustomFields={renameListingCustomFields}
                  setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                  buildingName={buildingName}
                  fieldOrder={safeOrderedFields}
                  moveField={moveField}
                  saveFieldOrder={saveFieldOrder}
                  uniqueFieldNames={uniqueFieldNames}
                />
                <TableRow classes={{ row: classes.spaceNotesBreakerRow }}>
                  {dividerColumns.map((key) => (
                    <TableContentCell
                      classes={{ tableCell: classes.spaceNotesBreaker }}
                      key={key}
                    />
                  ))}
                </TableRow>
                <DndProvider backend={BACKEND_FOR_DND}>
                  {rowsByFieldName.map(({ field, values }, index) => (
                    <DraggableMetadataRow
                      index={index}
                      key={field.name}
                      label={field.name}
                      surveySpaces={surveySpaces}
                      field={field}
                      values={values}
                      updateListing={updateListing}
                      createCustomListingField={createCustomListingField}
                      updateListingCustomField={updateListingCustomField}
                      deleteListingCustomField={deleteListingCustomField}
                      renameListingCustomFields={renameListingCustomFields}
                      setFieldMenuAnchorEl={setFieldMenuAnchorEl}
                      buildingName={buildingName}
                      fieldOrder={safeOrderedFields}
                      moveField={moveField}
                      saveFieldOrder={saveFieldOrder}
                      uniqueFieldNames={uniqueFieldNames}
                    />
                  ))}
                </DndProvider>
                {addingNewField && (
                  <AddCustomFieldTableRow
                    classes={{
                      metadataKeyCell: classes.metadataKeyCell,
                      editableCell: classes.editableCell,
                    }}
                    boldName
                    objectIds={spaceIds}
                    editableCellClasses={editableCellClasses}
                    createCustomFields={(newCustomFieldData) => {
                      if (
                        newCustomFieldData.name &&
                        !uniqueFieldNames.has(newCustomFieldData.name)
                      ) {
                        createCustomListingFields(
                          spaceIds,
                          newCustomFieldData.name,
                          newCustomFieldData.valueMap
                        )
                      }
                      setAddingNewField(false)
                    }}
                    updateListingCustomField={updateListingCustomField}
                    deleteListingCustomField={deleteListingCustomField}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {surveySpaces.length === 0 && (
          <div className={classes.pagePadding}>
            <div className={classes.emptyStateContainer}>
              <Typography
                className={classes.emptyStateHeader}
                variant="emptyStateHeader"
              >
                Add a Space to This Building
              </Typography>
              <Typography variant="bodyText">
                Tailor what you show with custom fields that reflect your
                Tenant’s needs.
              </Typography>
            </div>
          </div>
        )}
      </div>
      {AddSpaceToBuildingMenuComponent}
      {AddExistingSpacesToBuildingModalComponent}
      <Modal
        content={MODALS.ADD_FIELDS_FROM_ANOTHER}
        onClose={() => setShowAddFromAnotherModal(false)}
        open={showAddFromAnotherModal}
        childProps={{
          // this modal handles updating the building on the backend,
          // mutate just refreshes the local SWR data
          updateCustomFields: (updatedListings) =>
            mutate({
              ...surveyBuilding,
              survey_listings: surveyBuilding.survey_listings.map((sl) => ({
                ...sl,
                listing: updatedListings.find((l) => l.id === sl.listing.id),
              })),
            }),
          parentObjectIds: spaceIds,
          surveyId,
          type: 'listing',
        }}
      />
      {SpaceFieldMenuComponent}
      {DeleteSpaceFieldModalComponent}
      {ConfirmChangeFieldTypeModalComponent}
    </ModalComponent>
  )
}
