import { fetchApi } from './rootApi'

const API_ROOT = 'custom_fields'

// TODO: add tracking for each of these

export const customFieldApi = {
  createField: async ({ surveyId, templateType, label, dataType, order }) =>
    fetchApi({
      endpoint: `${API_ROOT}/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          surveyId,
          templateType,
          label,
          dataType,
          order,
        }),
      },
    }),
  changeFieldType: async ({ fieldId, value, type }) =>
    fetchApi({
      endpoint: `${API_ROOT}/${fieldId}/change_field_type/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ new_type: type, new_value: value }),
      },
    }),

  updateFieldLabel: async ({ fieldId, label }) =>
    fetchApi({
      endpoint: `${API_ROOT}/${fieldId}/change_field_label/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({ new_label: label }),
      },
    }),

  deleteField: async ({ fieldId }) =>
    fetchApi({
      endpoint: `${API_ROOT}/${fieldId}/delete_field/`,
      fetchArgs: {
        method: 'POST',
      },
    }),

  changeFieldTemplate: async ({ fieldId, surveyId, templateType }) =>
    fetchApi({
      endpoint: `${API_ROOT}/${fieldId}/change_field_template/`,
      fetchArgs: {
        method: 'POST',
        body: JSON.stringify({
          surveyId,
          templateType,
        }),
      },
    }),
}
