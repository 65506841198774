import {
  Backdrop,
  Container,
  Dialog,
  Fade,
  Hidden,
  Slide,
  Tooltip,
  Typography,
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import lodash from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Api from 'rest-fetcher-redux'
import useSWR from 'swr'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import { useErrorHandler } from 'react-error-boundary'
import {
  Breadcrumbs,
  Button,
  CardUIIcon,
  CompareSpacesButton,
  DeleteIcon,
  FtuxSdpBanner,
  ListUIIcon,
  Loading,
  MAP_WINDOW_MIN_WIDTH_PX,
  MODALS,
  Modal,
  MoreIcon,
  NotInterestedIcon,
  OldSurveyMenu,
  STICKY_BAR_HEIGHT_PX,
  ScheduleTourIcon,
  ShowActivityIcon,
  SquareIconButton,
  SquareIconButtonPrimaryBorder,
  SurveyListingCardGrid,
  SurveyListingTable,
  TABLE_AND_MAP_VIEW_MIN_WIDTH_PX,
  TABLE_MIN_WIDTH_PX,
  TABLE_WINDOW_MIN_WIDTH_PX,
  TextButton,
  TextInput,
  TextSelectField,
  useBulkImport,
} from '~/legacy/components'
import { surveyApi } from '~/legacy/fetchApi'
import {
  setAddListingModalState,
  setSurvey,
  setSurveyBuildings,
  setSurveyListings,
  setTableRowClicksEnabled,
} from '~/legacy/store/actions/viewSurvey'
import { useFetch } from '~/support/useFetch'
import { useFlags } from '~/support/useFlags'

import { FtuxBanner } from '~/legacy/components/FtuxBanner'
import { AddBuildingToSurveyModal2 } from '~/legacy/components/modalComponents/AddBuildingToSurveyModal2'
import { ExportIcon, LinkIconSvg, ShareIcon } from '~/legacy/components/svgs'
import {
  BUILDING_INTEREST,
  DATE_ADDED_NEW_TO_OLD_SORT,
  DATE_ADDED_OLD_TO_NEW_SORT,
  LEASE_DASHBOARD,
  LEASE_LOGIN,
  LEASE_PROJECTS,
  PRICE_HIGH_TO_LOW,
  PRICE_LOW_TO_HIGH,
  RECOMMENDED_SORT,
  SIZE_LARGE_TO_SMALL,
  SIZE_SMALL_TO_LARGE,
  SURVEY_LISTING_STATUSES,
  UPDATED_NEW_TO_OLD,
  UPDATED_OLD_TO_NEW,
} from '~/legacy/consts'
import {
  BULK_IMPORT_CONSTANTS,
  getSdpViewLocalStorage,
  getSearchAddressForDisplay,
  isBroker,
  isTenant,
  parseGoogleAddressComponents,
  removeListingsFromSurvey,
  setSdpViewLocalStorage,
  sortSdpSurveyBuildings,
  sortSdpSurveyListings,
} from '~/legacy/utils'
import {
  useApiHelper,
  useIsSurveyUserInBdpBuildout,
} from '~/legacy/utils/hooks'
import { toast } from '~/legacy/utils/notifications'
import { useMessage } from '~/libraries/messaging.react'
import { copyToClipboard } from '~/support/copyToClipboard'
import ActivityDrawer from './ActivityDrawer'
import { EditLocationDrawer } from './EditLocationDrawer'
import MapDrawer from './MapDrawer'
import SurveyBulkImport from './SurveyBulkImport'

const DEFAULT_SELECTED_SURVEY_LISTINGS_SET = {}

const getDivHeight = (fullTableHeight, tableViewWithNoListings) => {
  if (tableViewWithNoListings) {
    return '100%'
  }
  return fullTableHeight
    ? `calc(100% - ${STICKY_BAR_HEIGHT_PX}px)`
    : 'fit-content'
}

const LEFT_CONTAINER_HORIZ_PADDING_PX = 32
const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    position: 'sticky',
    top: '-1px',
    zIndex: 3,
    background: '#fff',
    height: `${STICKY_BAR_HEIGHT_PX}px`,
  },
  actionsSticky: {
    borderBottom: '1px solid #e0e0e0',
  },
  breadcrumb: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    minHeight: '44px', // button height
    padding: `0 ${LEFT_CONTAINER_HORIZ_PADDING_PX}px`,
  },
  addListingButton: {
    padding: '0 16px',
    height: '36px',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: '1px',
  },
  viewButton: {
    width: '36px',
    height: '36px',
  },
  viewButtonMarginRight: {
    marginRight: '8px',
  },
  notInterestedButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '20px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
  },
  buildingsCount: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '16px',
  },
  buttonIcon: {
    marginRight: '6px',
  },
  editIcon: {
    marginLeft: '6px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  exportButton: {
    marginRight: (props) => (props.showMap ? '0' : '12px'),
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 8px',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#f3f3f3',
    },
  },
  lastHeaderButton: {
    marginRight: '0',
  },
  mapButton: {
    backgroundColor: '#ffffff',
    border: '1px solid #d8d8d8',
    marginLeft: '12px',
    height: '36px',
    padding: '0 16px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    padding: '0',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    marginLeft: 0,
  },
  shiftedRoot: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: `clamp(${MAP_WINDOW_MIN_WIDTH_PX}px, 33%, calc(100vw - ${TABLE_WINDOW_MIN_WIDTH_PX}px))`,
    minWidth: `${TABLE_MIN_WIDTH_PX}px`,
  },
  textInput: {
    width: '300px',
  },
  title: {
    cursor: 'pointer',
  },
  titleActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  titleContainer: {
    display: 'flex',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    padding: `24px 0 ${LEFT_CONTAINER_HORIZ_PADDING_PX}px 0`,
    borderBottom: '1px solid #E0E0E0',
  },
  secondButton: {
    marginLeft: '15px',
    padding: '0 16px',
    height: '40px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  actionsButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  pagePadding: {
    padding: `0 ${LEFT_CONTAINER_HORIZ_PADDING_PX}px`,
  },
  maxWidth: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    paddingBottom: (props) => (props.fullTableHeight ? '0' : '80px'),
    height: (props) =>
      getDivHeight(
        props.fullTableHeight,
        props.tableViewWithNoListingsOrBuildings
      ),
  },
  viewContainer: {
    height: (props) =>
      getDivHeight(
        props.fullTableHeight,
        props.tableViewWithNoListingsOrBuildings
      ),
  },
  fullHeight: {
    height: (props) => (props.fullTableHeight ? '100%' : 'fit-content'),
  },
  contentViewSwitchContainer: {
    paddingRight: '8px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backdropText: {
    fontSize: '18px',
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  tableViewImportListingsSection: {
    textAlign: 'center',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: 'auto',
    width: '100%',
    border: '1px dotted #e0e0e0',
    borderTop: 'none',
    borderRadius: '1px 1px 4px 4px',
    marginBottom: '32px',
  },
  loginModalContent: {
    padding: '40px 60px !important',
  },
}))

const CARDS_VIEW = 1
const TABLE_VIEW = 2
const CARDS_VIEW_URL_PARAM = 'cards'
const TABLE_VIEW_URL_PARAM = 'table'

const getSortOptions = (isCardsView, isBuildout = false) => {
  let options = { [RECOMMENDED_SORT]: 'Recommended' }

  if (isCardsView) {
    if (!isBuildout) {
      options = {
        ...options,
        [PRICE_LOW_TO_HIGH]: 'Price: Low to High',
        [PRICE_HIGH_TO_LOW]: 'Price: High to Low',
        [SIZE_SMALL_TO_LARGE]: 'Size: Small to Large',
        [SIZE_LARGE_TO_SMALL]: 'Size: Large to Small',
      }
    }
    options = {
      ...options,
      [UPDATED_NEW_TO_OLD]: 'Updated: New to Old',
      [UPDATED_OLD_TO_NEW]: 'Updated: Old to New',
    }
  }

  options = {
    ...options,
    [DATE_ADDED_NEW_TO_OLD_SORT]: 'Added: New to Old',
    [DATE_ADDED_OLD_TO_NEW_SORT]: 'Added: Old to New',
  }

  return options
}

const ViewSelectionActions = ({ user, isCardsView, setContentView }) => {
  const classes = useStyles()
  return (
    <div className={isBroker(user) ? classes.contentViewSwitchContainer : ''}>
      <SquareIconButtonPrimaryBorder
        className={clsx(classes.viewButton, classes.viewButtonMarginRight)}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setContentView(CARDS_VIEW)
        }}
        showPrimary={isCardsView}
      >
        <CardUIIcon />
      </SquareIconButtonPrimaryBorder>
      <SquareIconButtonPrimaryBorder
        className={clsx(
          classes.viewButton,
          isBroker(user) ? classes.viewButtonMarginRight : ''
        )}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setContentView(TABLE_VIEW)
        }}
        showPrimary={!isCardsView}
      >
        <ListUIIcon />
      </SquareIconButtonPrimaryBorder>
    </div>
  )
}

const TenantActions = ({
  isBdpBuildout = false,
  survey,
  surveyListings,
  requestTourLoading,
  setRequestTourLoading,
  declineTourLoading,
  setDeclineTourLoading,
  selectedSurveyListingsSet,
  setSelectedSurveyListings,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const apiHelper = useApiHelper()
  const allSurveyBuildings = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )
  const listingIds = useMemo(
    () =>
      surveyListings
        .filter((surveyListing) =>
          selectedSurveyListingsSet.has(surveyListing.id)
        )
        .map((surveyListing) => surveyListing.listing.id),
    [surveyListings, selectedSurveyListingsSet]
  )

  const updateSurveyBuildingStatusesLocal = (
    newInterest,
    setLoading,
    notifyText
  ) => {
    setLoading(true)
    const selectedSurveyListings = surveyListings.filter((sl) =>
      selectedSurveyListingsSet.has(sl.id)
    )
    const buildingIds = [
      ...new Set(selectedSurveyListings.map((sl) => sl.listing.building.id)),
    ]
    surveyApi
      .updateSurveyBuildingStatuses({
        surveyId: survey.id,
        buildingIds,
        interest: newInterest,
      })
      .then(() => {
        const newSurveyBuildings = []
        allSurveyBuildings.forEach((surveyBuildingLocal) => {
          if (buildingIds.includes(surveyBuildingLocal.building.id)) {
            newSurveyBuildings.push({
              ...surveyBuildingLocal,
              interest: newInterest,
            })
          } else {
            newSurveyBuildings.push(surveyBuildingLocal)
          }
        })
        dispatch(setSurveyBuildings(newSurveyBuildings))
        setSelectedSurveyListings(DEFAULT_SELECTED_SURVEY_LISTINGS_SET)
        toast(notifyText, {
          appearance: 'success',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <CompareSpacesButton
        textButton
        disabled={!surveyListings || !surveyListings.length}
        surveyId={survey.id}
        spaceIds={listingIds}
      />
      <TextButton
        variant="text"
        className={classes.notInterestedButton}
        startIcon={<ScheduleTourIcon />}
        loading={requestTourLoading}
        disabled={declineTourLoading || requestTourLoading}
        onClick={() => {
          if (isBdpBuildout) {
            updateSurveyBuildingStatusesLocal(
              BUILDING_INTEREST.FAVORITED,
              setRequestTourLoading,
              'Buildings favorited! Your broker has been notified'
            )
          } else {
            setRequestTourLoading(true)
            const newStatus = SURVEY_LISTING_STATUSES.TOUR_REQUESTED
            const surveyListingArray = []
            selectedSurveyListingsSet.forEach((surveyListingId) =>
              surveyListingArray.push({
                id: surveyListingId,
                new_status: newStatus,
                status_change: newStatus,
              })
            )
            apiHelper
              .updateSurveyListingStatuses({
                surveyListings: surveyListingArray,
                colors: theme.palette.primary,
              })
              .then(() => {
                const newSurveyListings = []
                surveyListings.forEach((surveyListingLocal) => {
                  if (selectedSurveyListingsSet.has(surveyListingLocal.id)) {
                    newSurveyListings.push({
                      ...surveyListingLocal,
                      status: newStatus,
                    })
                  } else {
                    newSurveyListings.push(surveyListingLocal)
                  }
                })
                dispatch(setSurveyListings(newSurveyListings))
                setRequestTourLoading(false)
                setSelectedSurveyListings(DEFAULT_SELECTED_SURVEY_LISTINGS_SET)
                toast('Tours requested! Your broker has been notified', {
                  appearance: 'success',
                })
              })
          }
        }}
      >
        {isBdpBuildout ? 'Favorite Buildings' : 'Request Tour'}
      </TextButton>
      <TextButton
        variant="text"
        className={classes.notInterestedButton}
        startIcon={<NotInterestedIcon />}
        loading={declineTourLoading}
        disabled={declineTourLoading || requestTourLoading}
        onClick={() => {
          if (isBdpBuildout) {
            updateSurveyBuildingStatusesLocal(
              BUILDING_INTEREST.NOT_INTERESTED,
              setDeclineTourLoading,
              'Buildings marked as not interested. Your broker has been notified.'
            )
          } else {
            setDeclineTourLoading(true)
            const newStatus = SURVEY_LISTING_STATUSES.DECLINED
            const surveyListingArray = []
            selectedSurveyListingsSet.forEach((surveyListingId) =>
              surveyListingArray.push({
                id: surveyListingId,
                new_status: newStatus,
                status_change: newStatus,
              })
            )
            apiHelper
              .updateSurveyListingStatuses({
                surveyListings: surveyListingArray,
                colors: theme.palette.primary,
              })
              .then(() => {
                const newSurveyListings = []
                surveyListings.forEach((surveyListingLocal) => {
                  if (selectedSurveyListingsSet.has(surveyListingLocal.id)) {
                    newSurveyListings.push({
                      ...surveyListingLocal,
                      status: newStatus,
                    })
                  } else {
                    newSurveyListings.push(surveyListingLocal)
                  }
                })
                dispatch(setSurveyListings(newSurveyListings))
                setDeclineTourLoading(false)
                setSelectedSurveyListings(DEFAULT_SELECTED_SURVEY_LISTINGS_SET)
                toast('Tours declined. Your broker has been notified', {
                  appearance: 'success',
                })
              })
          }
        }}
      >
        Not Interested
      </TextButton>
    </div>
  )
}

const BrokerActionsSelected = ({
  isBdpBuildout = false,
  survey,
  surveyListings,
  surveyBuildings,
  selectedSurveyListingsSet,
  removeListingsLoading,
  setSelectedSurveyListings,
  setRemoveListingsLoading,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // Get a map of the selected buildings and the listings selectd in each
  const selectedBuildingWithSurveyListings = {}
  surveyListings
    .filter((sl) => selectedSurveyListingsSet.has(sl.id))
    .forEach((sl) => {
      if (!selectedBuildingWithSurveyListings[sl.listing.building.id]) {
        selectedBuildingWithSurveyListings[sl.listing.building.id] = []
      }
      selectedBuildingWithSurveyListings[sl.listing.building.id].push(sl.id)
    })
  // Delete survey buildings that have all of their survey listings being removed
  const surveyBuildingIdsToDelete = isBdpBuildout
    ? Object.entries(selectedBuildingWithSurveyListings)
        .filter(
          ([key, value]) =>
            value.length ===
            surveyBuildings.find((sb) => sb.building.id === parseInt(key, 10))
              .surveyListings.length
        )
        .map(([key]) => parseInt(key, 10))
    : null
  const listingIds = useMemo(
    () =>
      surveyListings
        .filter((surveyListing) =>
          selectedSurveyListingsSet.has(surveyListing.id)
        )
        .map((surveyListing) => surveyListing.listing.id),
    [surveyListings, selectedSurveyListingsSet]
  )

  return (
    <div>
      <CompareSpacesButton
        textButton
        disabled={!surveyListings || !surveyListings.length}
        surveyId={survey.id}
        spaceIds={listingIds}
      />
      <TextButton
        variant="text"
        className={classes.notInterestedButton}
        startIcon={<DeleteIcon />}
        loading={removeListingsLoading}
        onClick={() => {
          setRemoveListingsLoading(true)
          removeListingsFromSurvey(
            survey.id,
            surveyListings,
            surveyBuildings,
            selectedSurveyListingsSet,
            dispatch,
            setSelectedSurveyListings,
            surveyBuildingIdsToDelete,
            () => setRemoveListingsLoading(false)
          )
        }}
      >
        Remove Spaces
      </TextButton>
    </div>
  )
}

function StickyActions({
  isUserInBuildout = false,
  survey,
  buildingsCountString,
  selectedSurveyListingsSet,
  setSelectedSurveyListings,
  surveyListings,
  surveyBuildings,
  isCardsView,
  setContentView,
  selectedSort,
  setSelectedSort,
  hideContentViewButtons = false,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector((s) => s.user)
  const [isSticky, setIsSticky] = useState(false)
  const ref = React.createRef()
  const [requestTourLoading, setRequestTourLoading] = useState(false)
  const [declineTourLoading, setDeclineTourLoading] = useState(false)
  const [removeListingsLoading, setRemoveListingsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // mount
  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )

    observer.observe(cachedRef)

    // unmount
    return () => observer.unobserve(cachedRef)
  }, [])

  return (
    <div
      className={clsx(classes.actions, isSticky ? classes.actionsSticky : '')}
      ref={ref}
    >
      <div
        className={clsx(classes.maxWidth, classes.pagePadding, classes.flexRow)}
      >
        <Typography className={classes.buildingsCount} variant="h3">
          {buildingsCountString}
        </Typography>
        {!!(
          isCardsView &&
          ((!isUserInBuildout && surveyListings && surveyListings.length) ||
            (isUserInBuildout && surveyBuildings && surveyBuildings.length))
        ) && (
          <TextSelectField
            label="Sort By"
            onChange={setSelectedSort}
            options={getSortOptions(isCardsView, isUserInBuildout)}
            selectedValue={selectedSort}
            setSelectedValue={setSelectedSort}
            showSelectedOptionIcon
            defaultValue={RECOMMENDED_SORT}
            width="227px"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          />
        )}
        <div className={classes.actionsContainer}>
          {/* If the tenant is on table view with some listings selected */}
          {!isBroker(user) &&
            !isCardsView &&
            !!selectedSurveyListingsSet.size && (
              <TenantActions
                isBdpBuildout={isUserInBuildout}
                survey={survey}
                surveyListings={surveyListings}
                requestTourLoading={requestTourLoading}
                setRequestTourLoading={setRequestTourLoading}
                declineTourLoading={declineTourLoading}
                setDeclineTourLoading={setDeclineTourLoading}
                selectedSurveyListingsSet={selectedSurveyListingsSet}
                setSelectedSurveyListings={setSelectedSurveyListings}
              />
            )}
          {/* If the user is on the cards view, or table with nothing selected, and we aren't hiding the buttons, then show the content view switch buttons */}
          {(!!isCardsView || !selectedSurveyListingsSet.size) &&
            !hideContentViewButtons && (
              <ViewSelectionActions
                user={user}
                isCardsView={isCardsView}
                setContentView={setContentView}
              />
            )}
          {/* If the broker is on the cards view or has nothing selected on the table view, show the add listing button  */}
          {!!isBroker(user) &&
            (!!isCardsView || !selectedSurveyListingsSet.size) && (
              <Button
                color="primary"
                className={classes.addListingButton}
                onClick={() =>
                  isUserInBuildout
                    ? setIsModalOpen(true)
                    : dispatch(setAddListingModalState({ open: true }))
                }
                shrinkButton
              >
                {isUserInBuildout ? 'Add Building' : 'Add Space'}
              </Button>
            )}
          {/* If the broker is on the table view and has some listings selected, show the broker action buttons */}
          {!!isBroker(user) &&
            !isCardsView &&
            !!selectedSurveyListingsSet.size && (
              <BrokerActionsSelected
                isBdpBuildout={isUserInBuildout}
                survey={survey}
                surveyListings={surveyListings}
                surveyBuildings={surveyBuildings}
                selectedSurveyListingsSet={selectedSurveyListingsSet}
                removeListingsLoading={removeListingsLoading}
                setSelectedSurveyListings={setSelectedSurveyListings}
                setRemoveListingsLoading={setRemoveListingsLoading}
              />
            )}
        </div>
      </div>
      <AddBuildingToSurveyModal2
        surveyId={survey.id}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  )
}

const getViewStringById = (viewId) => {
  if (viewId === CARDS_VIEW) {
    return CARDS_VIEW_URL_PARAM
  }
  if (viewId === TABLE_VIEW) {
    return TABLE_VIEW_URL_PARAM
  }
  return null
}

const getDefaultView = (user, viewString = null) => {
  if (isBroker(user)) {
    return viewString === CARDS_VIEW_URL_PARAM ? CARDS_VIEW : TABLE_VIEW
  }
  return viewString === TABLE_VIEW_URL_PARAM ? TABLE_VIEW : CARDS_VIEW
}

const isBadAuthResponseMessage = (responseMessage) => {
  return [
    'You do not have permission to perform this action.',
    'Authentication credentials were not provided.',
  ].includes(responseMessage)
}

function BrokerSurvey({ history, match }) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const queryParams = new URLSearchParams(useLocation().search)
  const desiredContentViewRaw = queryParams.get('view')

  const {
    setRawCsvHeaders,
    csvHeaderIndexLookup,
    loadedListingsFromCsv,
    setLoadedListingsFromCsv,
    loadedListingsFormatted,
    setLoadedListingsFormatted,
    mappedStandardFields,
    setMappedStandardFields,
    loadedListingFieldsObj,
    setLoadedListingFieldsObj,
    orderedListingFields,
    userMatchableCsvHeaders,
    validatedListings,
    setValidatedListings,
    removedHeaderIndices,
    setRemovedHeaderIndices,
    uploadedCsvFile,
    setUploadedCsvFile,
  } = useBulkImport()

  const user = useSelector((s) => s.user)
  const surveyRedux = useSelector((store) => store.pages.viewSurvey.survey)
  const survey = surveyRedux || {}
  const isUserInBuildout = useIsSurveyUserInBdpBuildout(user, survey)

  const addListingModalState = useSelector(
    (store) => store.pages.viewSurvey.addListingModalState
  )
  const surveyListingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyListings
  )
  const surveyBuildingsRedux = useSelector(
    (store) => store.pages.viewSurvey.surveyBuildings
  )
  const surveyListings = surveyListingsRedux || []
  const surveyBuildings = surveyBuildingsRedux || []

  const surveyId = match.params.id

  const fetch = useFetch()
  const key = `/api/survey/${surveyId}`
  const { data: project } = useSWR(key, () => fetch(key))

  const flags = useFlags()

  const [buildingToEdit, setBuildingToEdit] = useState()
  const [showEditLocation, setShowEditLocation] = useState(false)
  useMessage('EditBuildingLocation', (message) => {
    setBuildingToEdit(message.building)
    setShowEditLocation(true)
  })

  const [showShareModal, setShowShareModal] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [editing, setEditing] = useState(false)
  const [surveyName, setSurveyName] = useState(survey.name)
  const [userOptions, setUserOptions] = useState([])
  const [selectedSurveyListings, setSelectedSurveyListings] = useState(
    DEFAULT_SELECTED_SURVEY_LISTINGS_SET
  )
  const [surveyMenuAnchor, setSurveyMenuAnchor] = React.useState(null)
  const [listings, setListings] = useState([])
  const [hoveredBuildingId, setHoveredBuildingId] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [mapDrawerOpen, setMapDrawerOpen] = useState(true)
  const [activityDrawerOpen, setActivityDrawerOpen] = useState(false)

  const [selectedSort, setSelectedSort] = useState(RECOMMENDED_SORT)

  const [bulkImportFieldMatchModalOpen, setBulkImportFieldMatchModalOpen] =
    useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)

  const [userAcceptedFieldMatches, setUserAcceptedFieldMatches] =
    useState(false)

  // Only show the map when the screen is big enough.
  const allowShowMap = useMediaQuery(
    theme.breakpoints.up(TABLE_AND_MAP_VIEW_MIN_WIDTH_PX)
  )
  const forceCardView = !useMediaQuery(
    theme.breakpoints.up(TABLE_WINDOW_MIN_WIDTH_PX),
    {
      noSsr: true,
    }
  )
  const showMap = mapDrawerOpen && allowShowMap && !forceCardView

  const getDefaultContentView = () => {
    // If URL provided, use that
    let desiredContentViewFinal
    if (desiredContentViewRaw) {
      desiredContentViewFinal = getDefaultView(user, desiredContentViewRaw)
    } else {
      // If URL not provided, fallback to local storage
      const localStorageView = getSdpViewLocalStorage()
      if (localStorageView) {
        desiredContentViewFinal = getDefaultView(user, localStorageView)
      } else {
        // Else, default
        desiredContentViewFinal = getDefaultView(user)
      }
    }
    return desiredContentViewFinal
  }

  const [contentView, setContentView] = useState(() => getDefaultContentView())
  useEffect(() => {
    if (forceCardView) {
      setContentView(CARDS_VIEW)
    }
  }, [forceCardView])

  const isCardsView = contentView === CARDS_VIEW

  useEffect(() => {
    if (contentView) {
      const viewString = getViewStringById(contentView)
      setSdpViewLocalStorage(viewString)
      window.history.replaceState(null, null, `?view=${viewString}`)
    }
    setHoveredBuildingId(false)
    setSelectedSurveyListings(DEFAULT_SELECTED_SURVEY_LISTINGS_SET)
  }, [contentView])

  const tableViewWithNoListingsOrBuildings =
    !isCardsView &&
    !isLoading &&
    ((!isUserInBuildout && surveyListings.length === 0) ||
      (!!isUserInBuildout && surveyBuildings.length === 0))
  const cardViewWithNoBuildings =
    isCardsView && !isLoading && surveyBuildings.length === 0

  const classes = useStyles({
    showMap,
    fullTableHeight: isLoading || (isCardsView && surveyListings.length === 0),
    tableViewWithNoListingsOrBuildings,
  })

  const [gridSurveyListings, setGridSurveyListings] = useState([])
  const [gridSurveyBuildings, setGridSurveyBuildings] = useState([])

  const [surveyBuildingsMap, setSurveyBuildingsMap] = useState([])
  useEffect(() => {
    if (surveyBuildings.length && !isLoading) {
      // Take the individual survey listings and group them into their common buildings
      const surveyBuildingsMapLocal = {}
      // set the UUID needed by draggable
      surveyBuildings.forEach((sb, index) => {
        surveyBuildingsMapLocal[sb.building.id] = {
          ...sb,
          order: sb.order === null ? index : sb.order,
          surveyListings: [],
          uuid: `${sb.building.id}`,
        }
      })
      surveyListings.forEach((sl) => {
        const buildingRow = surveyBuildingsMapLocal[sl.listing.building.id]
        buildingRow.building = {
          ...sl.listing.building,
          hero_photo: buildingRow.building.hero_photo
            ? buildingRow.building.hero_photo
            : sl.listing.hero_photo,
        }
        buildingRow.surveyListings.push(sl)
      })
      const newTableData = lodash.orderBy(
        Object.values(surveyBuildingsMapLocal),
        'order'
      )
      setSurveyBuildingsMap(newTableData)
    } else if (
      !surveyListings.length &&
      !surveyBuildings.length &&
      !isLoading
    ) {
      // If we have no listings/buildings and we're done loading, then we've nothing to show!
      setSurveyBuildingsMap([])
    }
  }, [surveyListings, surveyBuildings, isLoading])

  useEffect(() => {
    // Whenever our data changes or user selects a new order, order the survey listings for the grid
    if (surveyListings) {
      if (selectedSort !== null) {
        const sortedSurveyListings = sortSdpSurveyListings(
          surveyBuildings,
          surveyListings,
          selectedSort
        )
        setGridSurveyListings(sortedSurveyListings)
      } else {
        setGridSurveyListings(surveyListings)
      }
    } else {
      setGridSurveyListings([])
    }
  }, [surveyListings, surveyBuildings, selectedSort])

  useEffect(() => {
    // Whenever our data changes or user selects a new order, order the survey buildings for the grid
    if (surveyBuildingsMap) {
      if (selectedSort !== null) {
        const sortedSurveyBuildings = sortSdpSurveyBuildings(
          surveyBuildingsMap,
          selectedSort
        )
        setGridSurveyBuildings(sortedSurveyBuildings)
      } else {
        setGridSurveyBuildings(surveyBuildingsMap)
      }
    }
  }, [surveyBuildingsMap, selectedSort])

  const handleError = useErrorHandler()

  const fetchSurvey = (redirectNoPermissionUser) => {
    Api.getProject({ id: match.params.id }).then((result) => {
      if (result && isBadAuthResponseMessage(result.detail)) {
        redirectNoPermissionUser()
      } else if (!result || !result.id) {
        handleError(new Error('Error loading survey.'))
      } else {
        dispatch(setSurvey(result))
        setSurveyName(result.name)
      }
    })
  }

  useEffect(() => {
    if (Array.isArray(surveyListings)) {
      const sortedSurveyListings = sortSdpSurveyListings(
        surveyBuildings,
        surveyListings,
        RECOMMENDED_SORT
      )
      setListings(
        sortedSurveyListings.map((surveyListing) => surveyListing.listing)
      )
    }
  }, [surveyListings, surveyBuildings])

  const fetchSurveyListings = (redirectNoPermissionUser) => {
    setIsLoading(true)
    return Api.getSurveyListings({ id: surveyId }).then((results) => {
      if (results && isBadAuthResponseMessage(results.detail)) {
        redirectNoPermissionUser()
      } else if (!results) {
        handleError(new Error('Error loading survey listings.'))
      } else {
        dispatch(setSurveyListings(results.survey_listings))
        dispatch(setSurveyBuildings(results.survey_buildings))
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    setGridSurveyListings([])
    setGridSurveyBuildings([])
    setSurveyBuildingsMap([])
    dispatch(setSurveyListings([]))
    dispatch(setSurveyBuildings([]))
    dispatch(setSurvey({}))

    // Ensure only one redirect goes off. Multiple messes with URL
    let redirected = false
    const redirectNoPermissionUser = () => {
      if (!redirected) {
        redirected = true
        let redirectUrl = ''
        if (user && user.loggedIn) {
          redirectUrl = isBroker(user) ? LEASE_PROJECTS : LEASE_DASHBOARD
        } else {
          // User an existing redirect param if one exists
          let redirect = ''
          const existingRedirect = queryParams.get('redirect')
          if (existingRedirect) {
            redirect = existingRedirect
          } else {
            redirect = `${encodeURIComponent(
              location.pathname
            )}${encodeURIComponent(location.search || '')}`
          }
          redirectUrl = `${LEASE_LOGIN}?redirect=${redirect}`
        }
        history.push(redirectUrl)
      }
    }

    fetchSurvey(redirectNoPermissionUser)
    fetchSurveyListings(redirectNoPermissionUser)
  }, [surveyId])

  useEffect(() => {
    if (survey && survey.id && user && isBroker(user)) {
      Api.getCompanyUsers({ id: survey.id }).then((result) => {
        if (result && result.length > 0) {
          // Convert user response to options
          setUserOptions(
            result.map((value) => {
              return {
                email: value.email,
                inputValue: value.email,
                userType: value.user_type,
              }
            })
          )
        }
      })
    }
  }, [survey.id, user])

  const updateSurveyName = () => {
    if (surveyName !== survey.name) {
      Api.updateProject({
        id: survey.id,
        body: {
          ...survey,
          name: surveyName,
        },
      }).then((data) => {
        if (data.id) {
          dispatch(setSurvey(data))
        }
        setEditing(false)
      })
    } else {
      setEditing(false)
    }
  }

  // Build a set of the checked (selected) survey listing ids
  const selectedSurveyListingsSet = new Set()
  Object.entries(selectedSurveyListings).forEach(([k, v]) => {
    if (v) {
      selectedSurveyListingsSet.add(parseInt(k, 10))
    }
  })

  // Format the table header of number of buildings selected
  let buildingsCount
  let countString
  if (isUserInBuildout) {
    countString = 'Building'
    buildingsCount = surveyBuildings.length
  } else {
    countString = 'Space'
    if (selectedSurveyListingsSet && selectedSurveyListingsSet.size > 0) {
      buildingsCount = selectedSurveyListingsSet.size
    } else {
      buildingsCount = surveyListings.length
    }
  }
  let buildingsCountString = `${buildingsCount} ${countString}${
    buildingsCount !== 1 ? 's' : ''
  }`
  if (selectedSurveyListingsSet && selectedSurveyListingsSet.size > 0) {
    buildingsCountString += ' Selected'
  }

  // With address
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [activeMapMarker, setActiveMapMarker] = useState(null)
  const scrollRef = React.useRef(null)

  // After loading the listings from the CSV, go through each and try to fetch/populate its address
  useEffect(() => {
    if (loadedListingsFromCsv && csvHeaderIndexLookup) {
      const buildings = loadedListingsFromCsv
      const indexFormattedListingData = []

      // Create an object of the listing/building values. We key off of the index of the header in the csv, rather than
      //   the string itself, to handle duplicates / changing names, etc.
      // ie: {0: '18 Tremont Street', ...}
      buildings.forEach((building) => {
        const newCsvFieldArray = {}
        Object.entries(building).forEach(([headerString, value]) => {
          newCsvFieldArray[csvHeaderIndexLookup[headerString]] = value
        })
        indexFormattedListingData.push(newCsvFieldArray)
      })

      // Now we need to google verify the addresses. Let's make a single request for all of the buildings,
      //   and then supplement our listing/building info with the google address values, and set state/save
      const raw_addresses = buildings.map((building) =>
        getSearchAddressForDisplay(building)
      )

      Api.verifyAddresses({ body: { raw_addresses } }).then((apiResults) => {
        let finalResults = []

        // We've got the google address data, now build our result set. Make sure the number of listings we have and the
        //   number of address results are the same, else, things won't line up...
        if (apiResults && apiResults.results) {
          const { results } = apiResults
          if (
            results &&
            results.length &&
            results.length === buildings.length
          ) {
            results.forEach((result, index) => {
              if (
                result &&
                result.AUTOCOMPLETE_RESULT &&
                result.GEOCODE_RESULT
              ) {
                // Format/extract the google data we need

                const addressComponents = parseGoogleAddressComponents(
                  result.GEOCODE_RESULT.address_components,
                  result.GEOCODE_RESULT.geometry
                )
                const newAddressValues = {
                  ...addressComponents,
                  latitude: result.GEOCODE_RESULT.geometry.location.lat,
                  longitude: result.GEOCODE_RESULT.geometry.location.lng,
                }

                // Add the processed google address info into the result
                finalResults.push({
                  ...indexFormattedListingData[index],
                  [BULK_IMPORT_CONSTANTS.FIELDS.ADDRESS.index]: {
                    value: {
                      GOOGLE_ADDRESS: { ...newAddressValues },
                      GOOGLE_AUTOCOMPLETE_RESULTS: {
                        ...result.AUTOCOMPLETE_RESULT,
                      },
                    },
                  },
                })
              } else {
                finalResults.push({ ...indexFormattedListingData[index] })
              }
            })
          } else {
            finalResults = [...indexFormattedListingData]
          }
        } else {
          finalResults = [...indexFormattedListingData]
        }
        setLoadedListingsFormatted(finalResults)
        setBackdropOpen(false)
      })
    }
  }, [loadedListingsFromCsv, csvHeaderIndexLookup])

  // When the user has accepted the field matchings, and we've loaded the listings
  if (
    loadedListingsFormatted &&
    orderedListingFields &&
    userAcceptedFieldMatches
  ) {
    return (
      <>
        <SurveyBulkImport
          survey={survey}
          removedHeaderIndices={removedHeaderIndices}
          setRemovedHeaderIndices={setRemovedHeaderIndices}
          validatedListings={validatedListings}
          setValidatedListings={setValidatedListings}
          orderedListingFields={orderedListingFields}
          csvHeaderIndexLookup={csvHeaderIndexLookup}
          loadedListingFieldsObj={loadedListingFieldsObj}
          setLoadedListingFieldsObj={setLoadedListingFieldsObj}
          mappedStandardFields={mappedStandardFields}
          setMappedStandardFields={setMappedStandardFields}
          LEFT_CONTAINER_HORIZ_PADDING_PX={LEFT_CONTAINER_HORIZ_PADDING_PX}
          uploadedCsvFile={uploadedCsvFile}
        />
      </>
    )
  }

  const removeActiveMapMarker = () => {
    setActiveMapMarker(null)
  }

  if (!survey || !survey.id) {
    return (
      <div className={classes.loadingContainer} style={{ marginTop: '50px' }}>
        <Loading isLoading size={40} className={classes.loading} />
      </div>
    )
  }
  return (
    <Container
      className={clsx(classes.root, { [classes.shiftedRoot]: showMap })}
      onClick={() => dispatch(setTableRowClicksEnabled(true))}
      onKeyUp={(event) => {
        // inline editing shenanigans
        if (['Enter', 'Escape'].includes(event.key)) {
          dispatch(setTableRowClicksEnabled(true))
        }
      }}
    >
      <FtuxBanner
        cta="View My Stuff"
        visible={
          isBroker(user) && !project?.id && !flags.has('ftux.banner.survey')
        }
        onCta={() => {
          location.pathname = '/my-stuff/surveys'
        }}
        onClose={() => {
          flags.complete('ftux.banner.survey')
        }}
      >
        Add surveys to projects from My Stuff
      </FtuxBanner>
      <FtuxSdpBanner survey={survey} />
      <div
        className={clsx(classes.breadcrumb, classes.maxWidth)}
        ref={scrollRef}
        onClick={removeActiveMapMarker}
      >
        {project?.id ? (
          <Breadcrumbs
            pieces={[
              {
                name: project.name,
                path: `/projects/${project.id}`,
                replace: true,
              },
              { name: survey.name },
            ]}
          />
        ) : (
          <Breadcrumbs
            pieces={[
              {
                name: 'Surveys',
                path: isBroker(user) ? '/surveys' : '/dashboard',
              },
              { name: survey.name },
            ]}
          />
        )}
        <div className={classes.titleActions}>
          <Tooltip title="Activity" position="bottom">
            <div>
              <SquareIconButton
                className={classes.headerButton}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  setActivityDrawerOpen(!activityDrawerOpen)
                }}
              >
                <ShowActivityIcon />
              </SquareIconButton>
            </div>
          </Tooltip>
          {isBroker(user) && (
            <>
              {!project?.id && (
                <Tooltip title="Share" position="bottom">
                  <div>
                    <SquareIconButton
                      className={classes.headerButton}
                      onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        setShowShareModal(true)
                      }}
                    >
                      <ShareIcon />
                    </SquareIconButton>
                  </div>
                </Tooltip>
              )}
              <SquareIconButton
                tooltipText="Export"
                className={clsx(classes.headerButton)}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  window.location = `/surveys/${surveyId}/export`
                }}
                disabled={!listings.length && !surveyBuildings.length}
                showAsDisabled={!listings.length && !surveyBuildings.length}
              >
                <ExportIcon />
              </SquareIconButton>
            </>
          )}
          <CompareSpacesButton
            classes={{
              iconButton: clsx(
                classes.headerButton,
                isTenant(user) && classes.lastHeaderButton
              ),
            }}
            surveyId={surveyId}
            spaceIds={surveyListings.map((sl) => sl.listing.id)}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
            }}
            disabled={!surveyListings || !surveyListings.length}
            showAsDisabled={!surveyListings || !surveyListings.length}
          />
          <SquareIconButton
            tooltipText="Copy Link"
            className={clsx(classes.headerButton)}
            onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              copyToClipboard(location.href)
            }}
          >
            <LinkIconSvg />
          </SquareIconButton>
          {isBroker(user) && survey.owner && survey.owner.id === user.id && (
            <>
              <SquareIconButton
                className={clsx(classes.headerButton, classes.lastHeaderButton)}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  setSurveyMenuAnchor(event.currentTarget)
                }}
              >
                <MoreIcon />
              </SquareIconButton>
              <OldSurveyMenu
                surveyId={survey.id}
                handleMenuClose={() => {
                  setSurveyMenuAnchor(null)
                }}
                id="survey-options-menu"
                anchorEl={surveyMenuAnchor}
                open={!!surveyMenuAnchor}
                onClose={() => setSurveyMenuAnchor(null)}
                onSurveyDelete={() => history.push(LEASE_PROJECTS)}
                elevation={2}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
              />
            </>
          )}
          {!!(allowShowMap && !mapDrawerOpen) && (
            <Button
              className={classes.mapButton}
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                setMapDrawerOpen(!mapDrawerOpen)
              }}
              shrinkButton
            >
              Show Map
            </Button>
          )}
        </div>
      </div>
      <div
        className={clsx(classes.pagePadding, classes.maxWidth)}
        onClick={removeActiveMapMarker}
      >
        <div className={classes.topBar}>
          <div
            className={classes.titleContainer}
            onMouseEnter={() => setShowEdit(true)}
            onMouseLeave={() => setShowEdit(false)}
          >
            {!editing && (
              <>
                <Typography
                  className={isBroker(user) ? classes.title : ''}
                  onClick={(event) => {
                    if (isBroker(user)) {
                      event.stopPropagation()
                      event.preventDefault()
                      setEditing(true)
                    }
                  }}
                  variant="h1"
                >
                  {survey.name}
                </Typography>
                {isBroker(user) && (
                  <Fade in={showEdit}>
                    <EditIcon
                      className={classes.editIcon}
                      onClick={() => setEditing(true)}
                    />
                  </Fade>
                )}
              </>
            )}
            {editing && (
              <TextInput
                autoFocus
                className={classes.textInput}
                label="Survey Name"
                onBlur={() => updateSurveyName()}
                onChange={(event) => setSurveyName(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    updateSurveyName()
                  }
                }}
                value={surveyName}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.tableContainer} onClick={removeActiveMapMarker}>
        <StickyActions
          isUserInBuildout={isUserInBuildout}
          survey={survey}
          buildingsCountString={buildingsCountString}
          selectedSurveyListingsSet={selectedSurveyListingsSet}
          setSelectedSurveyListings={setSelectedSurveyListings}
          surveyListings={gridSurveyListings}
          surveyBuildings={gridSurveyBuildings}
          isCardsView={isCardsView}
          setContentView={setContentView}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          hideContentViewButtons={forceCardView}
        />
        <div
          className={clsx(
            classes.pagePadding,
            classes.maxWidth,
            classes.viewContainer
          )}
        >
          <Fade in={isCardsView}>
            <div className={classes.fullHeight}>
              <Hidden xsUp={!isCardsView}>
                <div className={clsx(classes.flexCol, classes.fullHeight)}>
                  <SurveyListingCardGrid
                    isUserInBuildout={isUserInBuildout}
                    surveyListings={gridSurveyListings}
                    surveyBuildingsMap={gridSurveyBuildings}
                    setSurveyBuildingsMap={setSurveyBuildingsMap}
                    isCondensed={showMap}
                    setHoveredBuildingId={setHoveredBuildingId}
                    isLoading={isLoading}
                    setLoadedListingsFromCsv={setLoadedListingsFromCsv}
                    setRawCsvHeaders={setRawCsvHeaders}
                    setBulkImportFieldMatchModalOpen={
                      setBulkImportFieldMatchModalOpen
                    }
                    setBackdropOpen={setBackdropOpen}
                    setUploadedCsvFile={setUploadedCsvFile}
                    activeMapMarker={activeMapMarker}
                    noSurveyBuildings={cardViewWithNoBuildings}
                  />
                </div>
              </Hidden>
            </div>
          </Fade>
          <Fade in={!isCardsView}>
            <div
              className={
                tableViewWithNoListingsOrBuildings ? classes.flexColumn : ''
              }
            >
              <Hidden xsUp={isCardsView}>
                <div>
                  <SurveyListingTable
                    isUserInBuildout={isUserInBuildout}
                    history={history}
                    surveyId={survey.id}
                    surveyBuildingsMap={surveyBuildingsMap}
                    setSurveyBuildingsMap={setSurveyBuildingsMap}
                    selectedSurveyListings={selectedSurveyListings}
                    setSelectedSurveyListings={setSelectedSurveyListings}
                    setHoveredBuildingId={setHoveredBuildingId}
                    isCondensed={showMap}
                    isBroker={isBroker(user)}
                    isLoading={isLoading}
                    setBackdropOpen={setBackdropOpen}
                    setLoadedListingsFromCsv={setLoadedListingsFromCsv}
                    setRawCsvHeaders={setRawCsvHeaders}
                    setBulkImportFieldMatchModalOpen={
                      setBulkImportFieldMatchModalOpen
                    }
                    setUploadedCsvFile={setUploadedCsvFile}
                    activeMapMarker={activeMapMarker}
                  />
                </div>
              </Hidden>
            </div>
          </Fade>
        </div>
      </div>
      <EditLocationDrawer
        building={buildingToEdit}
        open={showEditLocation}
        onClose={() => setShowEditLocation(false)}
        onChange={(changes) => {
          console.log({ changes, surveyBuildings })
          dispatch(setSurveyBuildings([]))
          dispatch(
            setSurveyBuildings(
              surveyBuildings.map((surveyBuilding) => {
                return surveyBuilding.building.id === buildingToEdit.id
                  ? {
                      ...surveyBuilding,
                      building: {
                        ...surveyBuilding.building,
                        ...changes,
                      },
                    }
                  : surveyBuilding
              })
            )
          )
        }}
      />
      <Modal
        content={MODALS.NEW_USER}
        open={showShareModal}
        onClose={() => setShowShareModal(false)}
        childProps={{
          userOptions,
          setUserOptions,
          fetchSurvey,
        }}
      />
      <Modal
        content={MODALS.ADD_LISTING_MODAL}
        onClose={() => dispatch(setAddListingModalState({ open: false }))}
        childProps={{
          surveyId,
          fetchSurveyListings,
          preSelectedBuilding: addListingModalState.building,
          isUserInBuildout,
        }}
        open={addListingModalState.open}
      />
      <Modal
        content={MODALS.BULK_IMPORT_FIELD_MATCH}
        open={bulkImportFieldMatchModalOpen}
        onClose={() => {
          setUserAcceptedFieldMatches(true)
          setBulkImportFieldMatchModalOpen(false)
        }}
        disableBackDropClickClose
        childProps={{
          csvFields: userMatchableCsvHeaders,
          // Fields from the CSV, matched already
          fieldsWithMatches: loadedListingFieldsObj,
          setFieldsWithMatches: (f) => {
            setLoadedListingFieldsObj(f)
          },
          // The fields that we want mappings for
          mappedStandardFields,
          setMappedStandardFields,
          orderedListingFields,
          loadedListingFieldsObj,
          setLoadedListingFieldsObj: (f) => {
            setLoadedListingFieldsObj(f)
          },
        }}
      />
      <Modal
        content={MODALS.LOGIN_MODAL}
        onClose={() => setShowLoginModal(false)}
        open={showLoginModal}
        childProps={{
          surveyId,
          contentClassName: classes.loginModalContent,
        }}
      />
      <MapDrawer
        isUserInBuildout={isUserInBuildout}
        open={showMap}
        setOpen={setMapDrawerOpen}
        surveyListings={surveyListings}
        surveyBuildings={surveyBuildings}
        hoveredBuildingId={hoveredBuildingId}
        surveyId={surveyId}
        activeMapMarker={activeMapMarker}
        setActiveMapMarker={setActiveMapMarker}
      />
      <Dialog
        id="dialog-backdrop"
        maxWidth={false}
        open={activityDrawerOpen}
        onClose={() => setActivityDrawerOpen(false)}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
      >
        <ActivityDrawer
          open={activityDrawerOpen}
          setOpen={setActivityDrawerOpen}
          setAnonymousLoginModalOpen={setShowLoginModal}
          surveyId={surveyId}
        />
      </Dialog>

      {/* Loading backdrop for loading the CSV listings after the user selects the csv */}
      <Backdrop
        className={classes.backdrop}
        open={backdropOpen && !bulkImportFieldMatchModalOpen}
      >
        <div className={classes.backdropContent}>
          <Loading color="inherit" isLoading />
          <Typography className={classes.backdropText}>
            Importing Listings
          </Typography>
        </div>
      </Backdrop>
    </Container>
  )
}

export default BrokerSurvey
