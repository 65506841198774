import React from 'react'
import { useApiHelper } from '~/legacy/utils/hooks'
import { uploadFile } from '~/legacy/utils'

const FileExtractorInput = ({
  onExtracted,
  surveyId,
  setParentLoading = () => {},
  setUploadedCsvFile = () => {},
}) => {
  const apiHelper = useApiHelper()

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    handleSubmit(selectedFile).catch((error) => {
      console.error('Error processing property data:', error)
    })
  }

  const handleSubmit = async (selectedFile) => {
    if (!selectedFile) {
      console.error('No file selected')
      return
    }

    setParentLoading(true)

    // Upload the CSV file to S3 (via webapp)
    uploadFile(
      selectedFile,
      'bulk_import_pdf_file',
      { survey_id: surveyId },
      true
    ).then((result) => {
      if (result && result.data && result.data.file) {
        // FIXME: I'm reusing the same state, but would be better to have a separate variable
        // This comes from BulkImportHooks.jsx
        setUploadedCsvFile(result.data.file)
      }
    })

    try {
      // Upload the file
      const uploadResult = await apiHelper.uploadPropertyAsset(selectedFile)
      const { filename } = uploadResult

      // Extract data from the uploaded file
      const extractedData = await apiHelper.extractPropertyData({ filename })
      onExtracted(extractedData)
    } catch (error) {
      console.error('Error processing property data:', error)
    } finally {
      setParentLoading(false)
    }
  }

  return (
    <input
      accept=".pdf,.jpg,.jpeg,.png"
      style={{ display: 'none' }}
      id="file-input"
      type="file"
      onChange={handleFileChange}
    />
  )
}

export default FileExtractorInput
