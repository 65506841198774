// @ts-check
import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import {
  AddressAutocomplete,
  BuildingTitleCard,
  HelpIconSmall,
  SquareCheckbox,
} from '~/legacy/components'
import { Typography } from '~/legacy/components/themeComponents'
import { surveyBuildingApi } from '~/legacy/fetchApi'
import { addSurveyBuilding } from '~/legacy/store/actions/viewSurvey'
import {
  getSpaceNameByListing,
  getViewBuildingRoute,
  SnackbarUtils,
  useSurveySelector,
} from '~/legacy/utils'
import { unless } from '~/libraries/styled'
import { useChanges } from '~/support/useChange'
import { useScroll } from '~/support/useScroll'
import { useWhenever } from '~/support/useWhenever'
import { ActionModal2 } from '../modals/ActionModal2'
import { useTranslation } from '~/locales/translations'

const { SURVEY_BUILDING_ALREADY_EXISTS } = surveyBuildingApi.errorCodes

export const useStyles = makeStyles({
  autocomplete: {
    marginTop: '32px',
    marginBottom: '40px',
  },
  card: {
    marginTop: '28px',
  },
  title: {
    marginTop: '32px',
  },
  items: {
    marginTop: '16px',
    marginBottom: '32px',
    maxHeight: `${66 * 4 + 36}px`,
    borderTop: '1px solid #E0E0E0',
    borderBottom: unless('bottom', '1px solid #E0E0E0'),
    overflowY: 'auto',
  },
  item: {
    height: '66px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    gap: '12px',
  },
  checkbox: {
    color: '#111111',
    flex: 'none',
    margin: '0px',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    flex: '1',
  },
  date: {
    color: '#666666',
    whiteSpace: 'nowrap',
  },
  surveyUnavailable: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  surveyDisabled: {
    color: '#666666',
  },
})

export const AddBuildingToSurveyModal2 = ({
  surveyId,
  open,
  onClose,
  onSuccess = undefined,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const [building, setBuilding] = useState({})
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [surveyToCopyId, setSurveyToCopyId] = useState(null)

  const surveys = useMemo(() => {
    const surveys = building.surveys_with_spaces ?? []
    return surveys.filter((survey) => survey.id !== surveyId)
  }, [building, surveyId])

  useChanges(building, () => {
    setError('')
    setIsLoading(false)
  })

  useWhenever(open, () => {
    setBuilding({})
    setError('')
    setIsLoading(false)
    setIsSaving(false)
    setSurveyToCopyId(null)
  })

  const addBuildingData = (data = {}) => {
    return setBuilding((snapshot) => {
      return { ...snapshot, ...data }
    })
  }

  const close = () => {
    onClose?.()
  }

  const save = async () => {
    if (!building.address) {
      setError('Please enter an address')
      return
    }

    try {
      setIsSaving(true)

      const [, response] = await surveyBuildingApi.create({
        surveyId,
        building,
        serializerKey: 'sdp',
        copySpacesFromSurveyId: surveyToCopyId,
      })

      if (response.error_code) {
        throw new Error(response.error_code)
      }

      setIsSaving(false)

      dispatch(addSurveyBuilding(response.data))

      SnackbarUtils.success(t('toasts.buildingAdded'))

      if (onSuccess) {
        close()
        onSuccess(response.data)
      } else {
        history.push(getViewBuildingRoute(surveyId, response.data.building.id))
      }
    } catch (error) {
      setIsSaving(false)

      if (error?.message === SURVEY_BUILDING_ALREADY_EXISTS) {
        SnackbarUtils.success('Building already added to survey.')
      } else {
        SnackbarUtils.error('Error adding building to survey.')
      }

      close()
    }
  }

  return (
    <ActionModal2
      open={open}
      title={building.isFuseMatch ? 'Add Existing Building' : 'Add Building'}
      action="Add to Survey"
      loading={isLoading || isSaving}
      onAction={save}
      onClose={close}
    >
      {surveys.length > 0 ? (
        <Spaces
          value={surveyToCopyId}
          onChange={setSurveyToCopyId}
          building={building}
          surveys={surveys}
        />
      ) : (
        <div className={classes.autocomplete}>
          <AddressAutocomplete
            error={!!error}
            helperText={error}
            label="Address"
            showExistingBuildings
            excludeExistingSurveyBuildings
            autoFocus
            surveyId={surveyId}
            fetchFreshBuildingData
            setAddressValues={addBuildingData}
            setBuildingValues={addBuildingData}
            overrideBuildingValues={setBuilding}
            controlledValue={building}
          />
        </div>
      )}
    </ActionModal2>
  )
}

export const Spaces = (props) => {
  const [itemsRef, setItemsRef] = useState(null)
  const classes = useStyles(useScroll(itemsRef))

  const { survey } = useSurveySelector(props.surveyId, 'bdp', false)
  const useNewCustomFields = survey?.opted_in_new_custom_fields
  const modalDescription = useNewCustomFields
    ? 'Select a survey to import building and space data from'
    : 'Select a survey to import spaces from'

  const surveyDescription = (survey) => {
    return useNewCustomFields
      ? survey.project?.name || 'Availability'
      : survey.spaces_in_building
          .map(getSpaceNameByListing)
          .map((name) => name || 'Availability')
          .join(', ')
  }

  return (
    <>
      <BuildingTitleCard building={props.building} className={classes.card} />
      <Typography className={classes.title}>{modalDescription}</Typography>
      <div ref={setItemsRef} className={classes.items}>
        {props.surveys.map((survey) => {
          const disabledSurvey =
            useNewCustomFields && !survey.opted_in_new_custom_fields

          return (
            <div key={survey.id} className={classes.item}>
              <div>
                <SquareCheckbox
                  className={classes.checkbox}
                  checked={props.value === survey.id}
                  onClick={() => {
                    props.onChange?.(
                      survey.id === props.value ? null : survey.id
                    )
                  }}
                  disabled={disabledSurvey}
                />
              </div>
              <div className={classes.text}>
                <Typography
                  variant="boldText"
                  className={clsx(disabledSurvey && classes.surveyDisabled)}
                >
                  {survey.name}
                </Typography>
                <Typography
                  variant="textSmall"
                  className={clsx(disabledSurvey && classes.surveyDisabled)}
                >
                  {surveyDescription(survey)}
                </Typography>
              </div>
              <Typography noWrap variant="textSmall" className={classes.date}>
                {disabledSurvey ? (
                  <Tooltip title="Contact support to make this survey accessible">
                    <div
                      className={clsx(
                        classes.surveyUnavailable,
                        classes.surveyDisabled
                      )}
                    >
                      Unavailable 
                      {' '}
                      <HelpIconSmall />
                    </div>
                  </Tooltip>
                ) : (
                  `Updated ${format(Date.parse(survey.updated_at), 'MM/dd/yy')}`
                )}
              </Typography>
            </div>
          )
        })}
      </div>
    </>
  )
}
